import { ReactComponent as QuestionIcon } from '../../../assets/icons/question.svg';
import { ReactComponent as TimeIcon } from '../../../assets/icons/time.svg';
import { ReactComponent as CupIcon } from '../../../assets/icons/cup.svg';
import { ReactComponent as GiftIcon } from '../../../assets/icons/gift.svg';

import s from './style.module.scss';

import RaffleAboutCard from 'src/components/raffles/AboutCard';

const About = () => {
  return (
    <div className={s.about}>
      <h4 className={s.about__title}>About raffles</h4>
      <div className={s.about__cardList}>
        <RaffleAboutCard
          icon={<QuestionIcon />}
          title='How do raffles work?'
          text='Purchase entries for a chance to win exciting prizes. Each entry gives you one ticket in the raffle draw. The more entries you have, the higher your chances of winning!'
        />

        <RaffleAboutCard
          icon={<GiftIcon />}
          title='What can I win?'
          text='Prizes vary for each raffle and can include cash, gaming equipment, exclusive merchandise, and more. Check each raffle for its specific prize details.'
        />

        <RaffleAboutCard
          icon={<TimeIcon />}
          title='When are winners drawn?'
          text='Raffle drawings occur when all entries are filled or at the specified end date, whichever comes first. Winners are announced live on stream and contacted directly.'
        />

        <RaffleAboutCard
          icon={<CupIcon />}
          title='How do I claim my prize?'
          text="If you're a winner, we'll contact you using the information provided during entry purchase. Make sure your contact details are up to date!"
        />
      </div>
    </div>
  );
};

export default About;
