import { ReactComponent as ShuffleLogoIcon } from 'src/assets/icons/shuffleLogo.svg';
import { ReactComponent as RaneScapeLogoIcon } from 'src/assets/icons/RuneScapeLogo.svg';
import cn from 'classnames';

import s from './style.module.scss';
import Button from 'src/ui/Button';
import { useSearchParams } from 'react-router';
import Divider from 'src/ui/Divider';

const Hero = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // const type = searchParams.get('type');

  // const handleTypeChange = (type: string) => {
  //   setSearchParams((prev) => {
  //     prev.set('type', type);
  //     return prev;
  //   });
  // };

  return (
    <div className={s.hero}>
      <div className={s.hero__container}>
        <div className={s.hero__titleBlock}>
          <h3 className={s.hero__title}>Giveaways</h3>

          <Divider className={s.hero__divider} borderWidth='1px' color='#FFFFFF' />
          <p className={s.hero__subtitle}>
            {/* Multiple Shuffle & RuneScape Giveaways active every week! */}
            Multiple Giveaways active every week!
          </p>

          {/* <div className={s.buttonGroup}>
            <div className={s.buttonGroup__content}>
              <Button
                onClick={() => handleTypeChange('Runescape')}
                className={cn(
                  s.buttonGroup__button,
                  searchParams.get('type') === 'Runescape' && s.buttonGroup__active,
                )}>
                <RaneScapeLogoIcon />
              </Button>
              <Button
                onClick={() => handleTypeChange('Shuffle')}
                className={cn(
                  s.buttonGroup__button,
                  searchParams.get('type') === 'Shuffle' && s.buttonGroup__active,
                )}>
                <ShuffleLogoIcon />
              </Button>
            </div>
          </div> */}

          {/* <div className={s.hero__btnContainer}>
            <Button isBlack className={s.hero__btn}>
              <RaneScapeLogoIcon />
            </Button>

            <Button className={s.hero__btn}>
              <ShuffleLogoIcon />
            </Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
