import React, { useEffect, useState } from 'react';
import { LogDTO } from 'src/types';
import { axiosGet } from 'src/utils/httpClient';
import 'src/assets/CSS/Admin.scss';
import { formatDateYearTime } from '../../components/Crates';

const StoreLogs = () => {
  const getLogs = async (): Promise<Array<LogDTO>> => axiosGet(`/admin/store-logs`);

  const [logs, setLogs] = useState<Array<LogDTO>>([]);
  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    getLogs().then((response: Array<LogDTO>) => {
      setLogs(response);
    });
  }, []);

  return (
    <div>
      <div className='Section-heading'>
        <h4>Store Logs</h4>
      </div>
      <div className='Bonus-hunt-search'>
        <div className='search-input'>
          <img src='/navigation/search.svg' width={20} />
          <input
            type='text'
            placeholder='Search'
            defaultValue={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className='logs'>
        {logs
          .filter((log) => !search || log.message.toLowerCase().includes(search.toLowerCase()))
          .map((log) => {
            return (
              <ul>
                <li>{formatDateYearTime(log.date)}</li>
                <li>{log.message}</li>
              </ul>
            );
          })}
      </div>
    </div>
  );
};

export default StoreLogs;
