import { DateTime } from 'luxon';

// export function numberWithCommas(x: number) {
//   if (typeof x !== 'number') return x;

//   const fractions = x.toString().split('.');
//   const twoDecimal = fractions.length > 1 ? fractions[1].substring(0, 2) : '00';
//   return fractions[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + twoDecimal;
// }

export function numberWithCommas(x: number | any, decimalPlaces: number = 2) {
  if (typeof x !== 'number') return x;

  const fractions = x.toString().split('.');
  const decimals =
    fractions.length > 1 ? fractions[1].substring(0, decimalPlaces) : ''.padEnd(decimalPlaces, '0');

  return (
    fractions[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
    (decimalPlaces > 0 ? '.' + decimals.padEnd(decimalPlaces, '0') : '')
  );
}

export const calculateVIPRewards = (wager: number, withComma?: boolean) => {
  let rewardNumber = 0;
  if (wager < 10000) {
    rewardNumber = 0;
  }
  if (wager >= 10000 && wager < 50000) {
    rewardNumber = 20;
  }
  if (wager >= 50000 && wager < 100000) {
    rewardNumber = 100;
  }
  if (wager >= 100000 && wager < 250000) {
    rewardNumber = 200;
  }
  if (wager >= 250000 && wager < 500000) {
    rewardNumber = 500;
  }
  if (wager >= 500000 && wager < 1000000) {
    rewardNumber = 1000;
  }
  if (wager >= 1000000 && wager < 2500000) {
    rewardNumber = 2000;
  }
  if (wager >= 2500000 && wager < 5000000) {
    rewardNumber = 5000;
  }
  if (wager >= 5000000 && wager < 10000000) {
    rewardNumber = 10000;
  }
  if (wager >= 10000000 && wager < 50000000) {
    rewardNumber = 20000;
  }
  if (wager >= 50000000) {
    rewardNumber = 100000;
  }

  const rewardNumberWithComma = numberWithCommas(rewardNumber);

  return withComma
    ? rewardNumberWithComma.substring(0, rewardNumberWithComma.length - 3)
    : rewardNumber;
};

export function msToTime(ms: number): any {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: sec,
  };
}

export const chunk = (arr: Array<any>, size: number): Array<Array<any>> => {
  const newArr: any[] = [];
  while (arr.length) newArr.push(arr.splice(0, size));
  return newArr;
};

// export const endDate = (end: any): Array<string> => {
//   const fix = (value: number): string => {
//     return value < 10 ? '0' + value.toFixed(0) : value.toFixed(0);
//   };
//   let delta = Math.abs(Date.parse(end) - new Date().getTime()) / 1000;

//   const days = Math.floor(delta / 86400);
//   delta -= days * 86400;

//   const hours = Math.floor(delta / 3600) % 24;
//   delta -= hours * 3600;

//   const minutes = Math.floor(delta / 60) % 60;
//   delta -= minutes * 60;

//   const seconds = delta % 60;

//   return [fix(days), fix(hours), fix(minutes), fix(seconds)];
// };

export const endDate = (end) => {
  const fix = (value) => {
    return value < 10 ? '0' + Math.floor(value) : Math.floor(value).toString();
  };

  const now = DateTime.now().setZone('America/New_York'); // Current time in EST
  const delta = end.diff(now, ['days', 'hours', 'minutes', 'seconds']).toObject();

  const days = delta.days || 0;
  const hours = delta.hours || 0;
  const minutes = delta.minutes || 0;
  const seconds = delta.seconds || 0;

  return [fix(days), fix(hours), fix(minutes), fix(seconds)];
};
