import React from 'react';
import '../assets/CSS/Casino.scss';
import '../assets/CSS/Casino.responsive.scss';

export default function PrivacyPolicy() {
  return (
    <div className='App-contents'>
      <div className='hero'>
        <div className='Hero-Content'>
          <div className='Landing-heading'>
            <h2 className='Main-heading-largest Heading-padded'>Privacy Policy</h2>
            <div className='Heading-divider' />
            <p className='Main-subheading' style={{ color: 'white' }}>
              Privacy Policy for BTCs.GG
            </p>

            <div className='Casino-card-Container'>
              <div className='Text-Content-card PartnerList'>
                <p>
                  BTCs.GG ("we," "us," or "our") is committed to protecting the privacy and security
                  of our website visitors and users ("you" or "your"). This Privacy Policy describes
                  how we collect, use, disclose, and protect personal information that we receive
                  from website visitors and users. By using our website, you consent to the terms of
                  this Privacy Policy.
                </p>
                <br />
                <p>
                  <b>Information We Collect</b>
                  <br />
                  We may collect the following types of personal information from you:
                  <li>
                    Usage information, such as your IP address, browser type, and device type, when
                    you visit our website;
                  </li>
                  <li>Any other information you choose to provide us.</li>
                  We also collect general purpose analytic data, which may include information such
                  as the pages you visit on our website, the time and date of your visit, and the
                  type of browser you use. We collect this data to analyze and improve the
                  performance of our website. We also collect your Twitch username if you choose to
                  log in to our website using your Twitch account. We do not collect your Twitch
                  password or any other personal information from your Twitch account.
                </p>
                <br />
                <p>
                  <b>How We Use Your Information</b>
                  <br />
                  We may use your personal information for the following purposes:
                  <li>To provide you with the products or services you request;</li>
                  <li>To communicate with you about your orders, products, or services;</li>
                  <li>To improve our website, products, or services;</li>
                  <li>To customize your experience on our website;</li>
                  <li>
                    To send you marketing communications about our products or services that may
                    interest you;
                  </li>
                  <li>To comply with legal obligations or protect our legal rights.</li>
                  We also collect general purpose analytic data, which may include information such
                  as the pages you visit on our website, the time and date of your visit, and the
                  type of browser you use. We collect this data to analyze and improve the
                  performance of our website.
                </p>
                <br />
                <p>
                  <b>How We Share Your Information</b>
                  <br />
                  We may share your personal information with third-party service providers that
                  help us operate our website or provide products or services to you. We may also
                  share your personal information with government authorities or law enforcement
                  officials to comply with legal requirements or protect our legal rights.
                </p>
                <br />
                <p>
                  <b>Your Choices</b>
                  <br />
                  You can choose not to provide personal information to us, but this may limit your
                  ability to use certain features of our website or receive certain products or
                  services. You can do the following at any time by contacting us at
                  <a href='mailto:admin@onlytk.xyz'>admin@onlytk.xyz</a>
                </p>
                <br />
                <p>
                  <b>Security</b>
                  <br />
                  We take reasonable measures to protect your personal information from unauthorized
                  access, disclosure, or misuse. However, we cannot guarantee the security of your
                  personal information, and you provide your personal information at your own risk.
                </p>
                <br />
                <p>
                  <b>Changes to this Privacy Policy</b>
                  <br />
                  We may update this Privacy Policy from time to time by posting the revised policy
                  on our website. The revised policy will be effective as of the date it is posted.
                </p>
                <br />
                <p>
                  <b>Contact Us</b>
                  <br />
                  If you have any questions about this Privacy Policy, please contact us at
                  <a href='mailto:admin@onlytk.xyz'>admin@onlytk.xyz</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // return (<>
  //     <div className="App-contents">
  //         <br/>
  //         <div className="Profile-general">
  //             <div className="Account-settings-container" style={{width: 'calc(100% - 20px)', overflow: 'hidden'}}>
  //                 <div className="Landing-heading Landing-heading-left">
  //                     <h4>Our Partners</h4>
  //                 </div>
  //                 <div className="PartnerList">
  //                     <CasinoPartners/>
  //                 </div>
  //             </div>
  //         </div>
  //         <br/> <br/>
  //         <Footer/>
  //     </div>
  // </>)
}
