import React, { useEffect, useState } from 'react';
import '../assets/CSS/Profile.scss';
import { IntegrationVerification, ItemDefinition, PlayerSettingsApi } from '../types';
import { axiosGet, axiosPost } from '../utils/httpClient';
import { dialogStyles, ModalProps } from 'src/layouts/AdminLayout';
import Dialog from '@mui/material/Dialog';
import { CopyAll } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
// import { makeStyles } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';
import Button from 'src/ui/Button';
import { useQuery } from '@tanstack/react-query';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  card: {
    minWidth: 100,
    maxHeight: 100,
    height: '100%',
    backgroundColor: 'transparent !important',
    margin: '15px',
    border: 'none',
    boxShadow: 'none',
  },
  cardContent: {
    backgroundSize: 'cover',
    backgroundColor: '#080808',
    height: '56px',
    border: '2px solid #2a2e38',
    textAlign: 'left',
    fontSize: '11px !important',
    color: '#cecece',
  },
}));

type ConnectModalProps = {} & ModalProps;

const getKickIntegration = async (): Promise<IntegrationVerification> =>
  axiosGet(`/player/verify-kick`);

function ConnectKickModal(props: ConnectModalProps) {
  const [verification, setVerification] = useState<IntegrationVerification | undefined>(undefined);
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open && !verification) {
      getKickIntegration().then((r) => {
        setVerification(r);
      });
    }
  }, [open]);

  if (!verification) {
    return (
      <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
        <div className='ConnectModal'>
          <h2>Loading...</h2>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
      <div className='ConnectModal'>
        <h2>
          <img src='/profile/kick.svg' />
          Connect Kick Account
        </h2>
        <br />
        <br />

        <span>Your Verification Code:</span>
        <br />
        <div className='CodeField'>
          <input type='text' defaultValue={verification.code} disabled={true} />
          <div className='Ico'>
            <CopyAll />
          </div>
        </div>

        <ol>
          <li>
            Go to our verification{' '}
            <a href='https://kick.com/btcs' target='_action'>
              <span className='Highlight'>Kick Channel</span>
            </a>
          </li>
          <li>
            Type in the following command:{' '}
            <span className='Highlight'>!verify {verification.code}</span>
          </li>
        </ol>
        <span>Your Verification Code becomes invalid in 5 minutes.</span>
        <br />
        <br />
        <br />
        <br />
        <Button onClick={handleClose} borderRadius='6px'>
          Close
        </Button>
      </div>
    </Dialog>
  );
}

const getProfileSettings = async (): Promise<PlayerSettingsApi> => axiosGet(`/player/settings`);
const setProfileSettings = async (request: PlayerSettingsApi): Promise<PlayerSettingsApi> =>
  axiosPost(`/player/settings`, request);

export const PlayerSettings = () => {
  const classes = useStyles();

  const [profile, setProfile] = useState<PlayerSettingsApi | undefined>(undefined);

  /** General Settings */
  const [steamTradeUrl, setSteamTradeUrl] = useState<string | undefined>(undefined);
  const [ethAddress, setEthAddress] = useState<string | undefined>(undefined);
  const [btcAddress, setBtcAddress] = useState<string | undefined>(undefined);
  const [shuffleUsername, setShuffleUsername] = useState<string | undefined>(undefined);
  const [updatingDetails, setUpdatingDetails] = useState<boolean>(false);

  /** Connected Accounts */
  const [discordConnected, setDiscordConnected] = useState<boolean>(false);
  const [kickConnected, setKickConnected] = useState<boolean>(false);
  const [openKick, setOpenKick] = React.useState(false);

  useEffect(() => {
    getProfileSettings()
      .then((prof: PlayerSettingsApi) => {
        setProfile(prof);
        setSteamTradeUrl(prof.steamTradeUrl);
        setEthAddress(prof.ethWalletAddress);
        setBtcAddress(prof.btcWalletAddress);
        setShuffleUsername(prof.shuffleUsername);
        setKickConnected(prof.kickConnected);
        setDiscordConnected(prof.discordConnected);
      })
      .catch((e) => {
        //@ts-ignore
        // window.location.href = '/';
      });
  }, []);

  const AccountSettingsForm = () => {
    return (
      <div className='Btcs-form'>
        <div className='Form-input-field'>
          <h6>ETH Address</h6>
          <div className='Form-input'>
            <div className='Form-icon'>
              <img src='/profile/eth.svg' />
            </div>
            <input
              type='text'
              placeholder='ETH Address...'
              defaultValue={ethAddress}
              onChange={(e) => {
                setEthAddress(e.target.value);
              }}
            />
          </div>
        </div>
        <div className='Form-input-field'>
          <h6>BTC Address</h6>
          <div className='Form-input'>
            <div className='Form-icon'>
              <img src='/navigation/btc.svg' />
            </div>
            <input
              type='text'
              placeholder='BTC Address...'
              defaultValue={btcAddress}
              onChange={(e) => {
                setBtcAddress(e.target.value);
              }}
            />
            <h5>Verify</h5>
          </div>
        </div>
        <div className='Form-input-field'>
          <h6>Shuffle Username</h6>
          <div className='Form-input'>
            <div className='Form-icon'>
              <img src='/profile/shuffle.png' />
            </div>
            <input
              type='text'
              placeholder='Shuffle Username...'
              defaultValue={shuffleUsername}
              onChange={(e) => {
                setShuffleUsername(e.target.value);
              }}
            />
            <h5>Verify</h5>
          </div>
        </div>
        <div className='Form-submission'>
          <Button
            borderRadius='6px'
            onClick={() => {
              if (updatingDetails) {
                return;
              }
              const newSteamTradeUrl = steamTradeUrl ?? '';
              const newEthAddress = ethAddress ?? '';
              const newBtcAddress = btcAddress ?? '';
              const newShuffleUsername = shuffleUsername ?? '';
              setUpdatingDetails(true);
              setProfileSettings({
                steamTradeUrl: newSteamTradeUrl,
                ethWalletAddress: newEthAddress,
                btcWalletAddress: newBtcAddress,
                shuffleUsername: newShuffleUsername,
                kickConnected: false,
                discordConnected: false,
              }).then((response) => {
                setUpdatingDetails(false);
                setProfile(response);
              });
            }}>
            {updatingDetails ? 'Updating...' : 'Update Profile'}
          </Button>
        </div>
      </div>
    );
  };

  const ConnectAccounts = () => {
    return (
      <div className='Account-integrations'>
        <div className='Integrations-list'>
          {discordConnected ? (
            <div className='Integration-option'>
              <div className='Integration Connected'>
                <img src='/profile/discord.svg' />
                <div className='Title'>
                  <span>Discord</span>
                </div>
                <CheckIcon />
              </div>
              <div className='Integration-heading'>
                <span>Connected</span>
              </div>
            </div>
          ) : (
            <div
              className='Integration-option'
              onClick={() => {
                localStorage.setItem('discord_integration', `${new Date().getTime() + 60_000}`);
                window.location.href =
                  'https://discord.com/oauth2/authorize?client_id=1231647510033072219&redirect_uri=https://btcs.gg/&response_type=code&scope=identify';
              }}>
              <div className='Integration'>
                <img src='/profile/discord.svg' />
                <div className='Title'>
                  <span>Discord</span>
                </div>
              </div>
              <div className='Integration-heading'>
                <span>Not Connected</span>
              </div>
            </div>
          )}
          {kickConnected ? (
            <div className='Integration-option'>
              <div className='Integration Connected'>
                <img src='/profile/kick.svg' />
                <div className='Title'>
                  <span>Kick</span>
                  <CheckIcon />
                </div>
              </div>
              <div className='Integration-heading'>
                <span>Connected</span>
              </div>
            </div>
          ) : (
            <div className='Integration-option' onClick={() => setOpenKick(true)}>
              <div className='Integration'>
                <img src='/profile/kick.svg' />
                <div className='Title'>
                  <span>Kick</span>
                </div>
              </div>
              <div className='Integration-heading'>
                <span>Not Connected</span>
              </div>
            </div>
          )}
        </div>
        <ConnectKickModal
          key={'integrate-kick'}
          open={openKick}
          onClose={() => setOpenKick(false)}
        />
      </div>
    );
  };

  if (!profile) {
    return <div className='App-contents' />;
  }

  return (
    <>
      <div className='App-contents'>
        <div className='Profile-general'>
          <div
            className='Account-settings-container'
            style={{ width: 'calc(100% - 20px)', overflow: 'hidden' }}>
            <div className='Landing-heading Landing-heading-left'>
              <h4>General</h4>
            </div>
            {AccountSettingsForm()}
          </div>
        </div>
        <div className='Account-settings-container' style={{ width: 'calc(100% - 20px)' }}>
          <div className='Landing-heading Landing-heading-left'>
            <h4 style={{ whiteSpace: 'nowrap' }}>Connect Accounts</h4>
          </div>
          {ConnectAccounts()}
        </div>
      </div>
    </>
  );
};
