import React from 'react';

import s from './style.module.scss';

const STATUS_BADGES = {
  active: '#52F164',
  pending: '#FFD700',
  upcoming: '#FFA500',
  completed: '#FF0000',
};

export const enum Status {
  Active = 'active',
  Pending = 'pending',
  Upcoming = 'upcoming',
  Completed = 'completed',
}

interface IStatusBadgeProps {
  status: Status;
  title: string;
}

const StatusBadge = ({ status, title }: IStatusBadgeProps) => {
  return (
    <div className={s.badge} style={{ color: STATUS_BADGES[status] }}>
      <div className={s.badge__point} style={{ backgroundColor: STATUS_BADGES[status] }} />
      {title}
    </div>
  );
};

export default StatusBadge;
