import React from 'react';

import s from './style.module.scss';
import { IRafflePublicWinner } from 'src/@types/raffles';
import Button from 'src/ui/Button';
import WinnerCard from './WinnerCard';
import useModal from 'src/hooks/useModal';
import Modal from 'src/ui/Modal';

interface IWinnerSectionProps {
  data: IRafflePublicWinner[];
}

const WinnersSection = ({ data }: IWinnerSectionProps) => {
  const { isOpen, openModal, closeModal } = useModal();
  const previewWinners = data?.slice(0, 5);

  return (
    <>
      <div className={s.winners}>
        <div className={s.winners__head}>
          <h3 className={s.winners__title}>
            Winners <span>({data?.length})</span>
          </h3>

          {data.length > 5 && <Button onClick={openModal}>Show More</Button>}
        </div>

        <div className={s.winners__list}>
          {previewWinners?.map((winner: IRafflePublicWinner) => (
            <WinnerCard item={winner} />
          ))}
        </div>
      </div>

      <Modal maxWidth='lg' isCloseIcon open={isOpen} handleClose={closeModal}>
        <div className={s.modal}>
          <h3 className={s.modal__title}>Winners</h3>
          <ul className={s.modal__list}>
            {data?.map((winner: IRafflePublicWinner) => (
              <li className={s.modal__item}>
                <WinnerCard item={winner} />
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default WinnersSection;
