import React from 'react';
import '../assets/CSS/Casino.scss';
import '../assets/CSS/Casino.responsive.scss';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

export default function Casinos() {
  return (
    <div className='App-contents'>
      <div className='hero'>
        <img className='Casino-coin1' src='/btc-coin-2.png' />
        <img className='Casino-coin2' src='/btcs-coin2.png' />
        <div className='Hero-Content'>
          <div className='Landing-heading'>
            <h2 className='Main-heading-largest Heading-padded'>Casino</h2>
            <div className='Heading-divider' />
            <p className='Main-subheading' style={{ color: 'white' }}>
              Use any of our sign up codes to qualify for our leaderboard, giveaways, and offers!
            </p>

            <div className='Casino-card-Container'>
              <div className='Casino-card PartnerList'>
                <img src='/shuffle-logo.svg' />
                <p className='ReferralCode'>
                  <span>
                    Code:{' '}
                    <span className='Code' style={{ padding: '0' }}>
                      <u>
                        <a href='https://shuffle.com/?r=btcs'>BTCS</a>
                      </u>
                    </span>
                  </span>
                </p>
                <p className='ReferralCode'>
                  <span>
                    Code:{' '}
                    <span className='Code' style={{ padding: '0' }}>
                      <u>
                        <a href='https://shuffle.com/?r=gray'>GRAY</a>
                      </u>
                    </span>
                  </span>
                </p>
                <p className='ReferralCode'>
                  <span>
                    Code:{' '}
                    <span className='Code' style={{ padding: '0' }}>
                      <u>
                        <a href='https://shuffle.com/?r=hunter'>HUNTER</a>
                      </u>
                    </span>
                  </span>
                </p>
                <p className='ReferralCode'>
                  <span>
                    Code:{' '}
                    <span className='Code' style={{ padding: '0' }}>
                      <u>
                        <a href='https://shuffle.com/?r=lance'>LANCE</a>
                      </u>
                    </span>
                  </span>
                </p>
                <p className='ReferralCode'>
                  <span>
                    Code:{' '}
                    <span className='Code' style={{ padding: '0' }}>
                      <u>
                        <a href='https://shuffle.com/?r=D3'>D3</a>
                      </u>
                    </span>
                  </span>
                </p>
                <p className='Benefit' style={{ marginTop: '30px' }}>
                  <CheckCircleOutlineOutlinedIcon />
                  $50 Deposit Bonus
                </p>
                <p className='Benefit'>
                  <CheckCircleOutlineOutlinedIcon />
                  Leaderboard
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // return (<>
  //     <div className="App-contents">
  //         <br/>
  //         <div className="Profile-general">
  //             <div className="Account-settings-container" style={{width: 'calc(100% - 20px)', overflow: 'hidden'}}>
  //                 <div className="Landing-heading Landing-heading-left">
  //                     <h4>Our Partners</h4>
  //                 </div>
  //                 <div className="PartnerList">
  //                     <CasinoPartners/>
  //                 </div>
  //             </div>
  //         </div>
  //         <br/> <br/>
  //         <Footer/>
  //     </div>
  // </>)
}
