import { DateTime } from 'luxon';

export const convertDateToIso = (value: string): string => {
  if (!value) return '';

  const isoDateTime = new Date(value).toISOString();
  return isoDateTime;
};

// export const convertToDatetimeLocalFormat = (utcDate: string): string => {
//   const dateObject = new Date(utcDate);

//   const localDate = dateObject.toLocaleString();
//   return localDate;
// };

export const convertToDatetimeLocalFormat = (utcDate: string): string => {
  const dateObject = new Date(utcDate);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');
  const hours = String(dateObject.getHours()).padStart(2, '0');
  const minutes = String(dateObject.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const convertUtcToEst = (utcDate: string) => {
  const dateInUtc = DateTime.fromISO(utcDate, { zone: 'utc' });

  const dateInEst = dateInUtc.setZone('America/New_York');

  return dateInEst.toFormat('yyyy-MM-dd HH:mm:ss');
};
