import React, { useEffect, useState } from 'react';
import { PlayerDetails, StoreItem } from '../types';
import { axiosGet, axiosPost } from '../utils/httpClient';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import '../assets/CSS/Store.scss';
// import { makeStyles } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';
import useAuthStore from 'src/store/authStore';
import { ModalProps } from 'src/layouts/AdminLayout';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  card: {
    maxWidth: 440,
    height: '400px',
    background: 'linear-gradient(180deg, #2a2e38, #212431) !important',
    margin: '25px',
    paddingTop: '15px',
    border: '1px solid #2a2e38',
    borderRadius: '20px !important',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 4px 4px 0px rgba(0, 0, 0, 0.14), 0px 4px 7px 0px rgba(0, 0, 0, 0.12) !important',
  },
  cardContent: {
    backgroundColor: 'transparent !important',
    textAlign: 'left',
    fontSize: '11px !important',
    color: '#cecece',
  },
  select: {
    '&:before': {
      borderColor: 'white !important',
    },
    '&:after': {
      borderColor: 'white !important',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white !important',
    },
    color: 'white !important',
  },
  icon: {
    fill: 'white !important',
  },
  accordion: {
    background: '#2c303e !important',
    borderRadius: '10px',
    color: 'white !important',
  },
}));

export type StoreModalProps = {
  item: StoreItem | undefined;
  setUserDetail: (detail: PlayerDetails) => any;
} & ModalProps;

const dialogStyles = {
  backgroundColor: '#1a1d23',
  color: 'white',
  padding: '25px 40px 0 15px',
  boxShadow: 'none',
  width: '400px',
  height: '450px',
  overflow: 'hidden',
};

type PurchaseItemRequest = {
  item: string;
};

function PurchaseItemModal(props: StoreModalProps) {
  const { onClose, open, setUserDetail } = props;

  const [submitting, setSubmitting] = useState<boolean>(false);

  const buyItem = async (request: PurchaseItemRequest): Promise<PlayerDetails> =>
    axiosPost(`/player/purchase-item`, request);

  const handleClose = () => {
    onClose();
  };

  const item = props.item;
  if (!item) {
    return <></>;
  }
  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
      <h2>Buy Item</h2>
      <div className='PurchaseModal'>
        <div className='ItemImage'>
          <img width={100} src={item!.definition.image} />
        </div>
        <h5>{item!.definition.name}</h5>
        <p>Are you sure you want to buy this Item for</p>
        <div className='ItemCost'>
          <div className='Balance'>
            <img src='/currency.svg' />
            <div className='Points-value'>
              <span>{item!.cost.toLocaleString('en-US')}</span>
            </div>
          </div>
        </div>

        <div className='ItemCost'>
          <button
            onClick={() => {
              if (!props.item || submitting) {
                return;
              }
              setSubmitting(true);
              buyItem({ item: props.item.id! }).then((response) => {
                setSubmitting(false);
                setUserDetail(response);
                onClose();
              });
            }}>
            {submitting ? 'Buying...' : 'Buy Item'}
          </button>
          <button className='Alt' onClick={() => props.onClose()}>
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  );
}

const getStoreItems = async (): Promise<Array<StoreItem>> => axiosGet(`/general/store-items`);

export const Store: React.FC = () => {
  const { setUser } = useAuthStore();

  const StoreItemsList = () => {
    const classes = useStyles();
    return (
      <Grid className={classes.gridList} spacing={0} container>
        {items.map((tile) => {
          return (
            <Card sx={{ minWidth: 340 }} className={classes.card}>
              <img src={tile.definition.image} height={200} />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom align='center' variant='h6' component='div'>
                  <span>{tile.definition.name}</span>
                </Typography>
                <br />
                <br />
                <Typography gutterBottom component='div'>
                  <p style={{ display: 'flex', justifyContent: 'left' }}>
                    <div className='Balance'>
                      <img src='/currency.svg' />
                      <div className='Points-value'>
                        <span>{tile.cost.toLocaleString('en-US')}</span>
                        <span className='Remaining'>•</span> {tile.quantity.toLocaleString('en-US')}{' '}
                        Left
                      </div>
                    </div>
                  </p>
                </Typography>
                <br />
                <div
                  className='Challenge-Tip Buy'
                  onClick={() => {
                    setItem(tile);
                    setOpen(true);
                  }}>
                  <svg
                    width={20}
                    height={20}
                    focusable='false'
                    aria-hidden='true'
                    viewBox='0 0 24 24'>
                    <path
                      fill='white'
                      d='M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z'></path>
                  </svg>
                  <p style={{ fontSize: '16px' }}>Buy</p>
                </div>
              </CardContent>
            </Card>
          );
        })}
      </Grid>
    );
  };

  const [items, setItems] = useState<Array<StoreItem>>([]);
  const [item, setItem] = useState<StoreItem | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getStoreItems().then((response) => {
      setItems(response);
    });
  }, []);

  return (
    <>
      <div className='App-contents Challenges VIP'>
        <div className='Landing-content'>
          <br />
          <br />
          <div className='Landing-heading'>
            <h2 className='Main-heading Main-heading-larger'>Store</h2>
            <div className='Heading-divider' />
            <p className='Main-subheading'>
              Points can be earned by watching streams and completing challenges.
            </p>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className='Bonus-hunts-container'>
            <div className='Bonus-hunts-content-container'>{StoreItemsList()}</div>
          </div>
          <br /> <br />
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <PurchaseItemModal open={open} onClose={handleClose} item={item} setUserDetail={setUser} />
      </div>
    </>
  );
};
