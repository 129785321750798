import React from 'react';
import MuiDivider from '@mui/material/Divider';
import cn from 'classnames';

const Divider = ({
  orientation = 'horizontal',
  color = 'rgba(255, 255, 255, 0.2)',
  className,
  borderWidth = '1px',
  style,
}: {
  orientation?: 'horizontal' | 'vertical';
  color?: string;
  borderWidth?: string;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <MuiDivider
      style={style}
      orientation={orientation}
      sx={{ borderColor: color, borderWidth: borderWidth }}
      className={cn(className)}
    />
  );
};

export default Divider;
