import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { formatDateYearTime } from 'src/components/Crates';
import { ModalProps } from 'src/layouts/AdminLayout';
import { Challenge, ConcludeSlotBattleRequest, Player } from 'src/types';
import { axiosPut } from 'src/utils/httpClient';

export type ConcludeChallengeModalProps = {
  editing?: Challenge;
  players: Array<Player>;
} & ModalProps;

export const dialogStyles = {
  backgroundColor: '#1a1d23',
  color: 'white',
  padding: '25px',
  boxShadow: 'none',
};

function ConcludeChallengeModal(props: ConcludeChallengeModalProps) {
  const { onClose, open, players } = props;

  const [warned, setWarned] = useState<boolean>(false);
  const [selected, setSelected] = useState<Player | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [search, setSearch] = useState<string | undefined>(undefined);

  const concludeWinner = async (request: ConcludeSlotBattleRequest): Promise<Array<Challenge>> =>
    axiosPut(`/moderate/set-challenge-winner`, request);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
      <h2>Conclude Challenge</h2>
      <div className='Btcs-form'>
        <div className='Form-input-field'>
          <h6>Winner</h6>
        </div>

        <div className='Form-input-field'>
          <div className='Games-search-result'>
            <div>
              <div className='Bonus-hunt-search'>
                <div className='search-input'>
                  <img src='/navigation/search.svg' width={20} />
                  <input
                    type='text'
                    placeholder='Search'
                    defaultValue={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className='Btcs-table'>
                <ul className='Header'>
                  <li>Name</li>
                  <li>Rank</li>
                  <li>Last Online</li>
                </ul>
                {players
                  .filter((player) => {
                    return !search || player.username.toLowerCase().includes(search.toLowerCase());
                  })
                  .map((player) => {
                    return (
                      <ul
                        className={'Entry ' + (player == selected ? 'SelectedEntry' : '')}
                        onClick={() => {
                          setSelected(player);
                        }}>
                        <li>{player.username}</li>
                        <li>{player.authority}</li>
                        <li>{formatDateYearTime(player.lastOnline)}</li>
                      </ul>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        <FormControlLabel
          control={
            <Checkbox name='Warning' checked={warned} onChange={(e) => setWarned(!warned)} />
          }
          label='I understand that this action cannot be undone.'
        />

        <div className='Form-submission'>
          <button
            className={warned && selected ? '' : 'Alt'}
            onClick={() => {
              if (submitting) {
                return;
              }
              if (!selected || !warned) {
                return;
              }
              setSubmitting(true);
              if (props.editing) {
                concludeWinner({
                  id: props.editing.id,
                  game: selected.username,
                }).then((r) => {
                  setWarned(false);
                  setSelected(undefined);
                  setSubmitting(false);
                  handleClose();
                });
              }
            }}>
            End Challenge & Reward Player
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default ConcludeChallengeModal;
