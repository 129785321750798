import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { formatDateYearTime } from './Crates';
import { Achievement, ItemDefinition } from '../types';
// import { makeStyles } from '@material-ui/core/styles';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
      background: 'transparent !important',
      borderRadius: '10px',
    },
  },
  table: {
    border: '1px solid #2a2e38 !important',
    borderRadius: '10px',
  },
  tableHeader: {
    background: '#121418 !important',
    border: '1px solid #2a2e38 !important',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      background: '#1b1d27 !important',
    },
    '&:nth-of-type(even)': {
      background: '#121418 !important',
    },
    border: '1px solid #2a2e38 !important',
  },
  headerCell: {
    background: '#121418 !important',
    color: 'rgb(223, 218, 228) !important',
    borderBottom: '1px solid #2a2e38 !important',
    '&:first-child': {
      borderTopLeftRadius: '10px',
    },
  },
  tableCell: {
    color: 'rgb(223, 218, 228) !important',
    borderBottom: '1px solid #2a2e38 !important',
    cursor: 'pointer',
  },
}));

interface Column {
  id: 'type' | 'date' | 'metadata';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any, row: any) => any;
}

const columns: readonly Column[] = [
  {
    id: 'type',
    label: 'Achievement',
    minWidth: 100,
    format: (v: any, row: any) => {
      if (v) {
        switch (v) {
          case 'VIP_REWARD_UNLOCKED':
            return 'Unlocked VIP Reward ' + (Number(row.metadata) + 1);
        }
      }
      return 'N/A';
    },
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 170,
    align: 'right',
    format: (value: any) => (value ? formatDateYearTime(value) : 'N/A'),
  },
];

export type AchievementsProps = {
  rows: Array<Achievement>;
  defs: Array<ItemDefinition>;
};

export const AchievementsTable: React.FC<AchievementsProps> = ({ rows, defs }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const classes = useStyles();
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', background: 'transparent' }}>
      <TableContainer sx={{ maxHeight: 840 }}>
        <Table className={classes.table} stickyHeader aria-label='sticky table'>
          <TableHead className={classes.tableHeader}>
            <TableRow className={classes.tableRow}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.headerCell}
                  style={{ minWidth: column.minWidth }}>
                  <span>{column.label}</span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow
                  className={classes.tableRow}
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];

                    return (
                      <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                        <span>
                          {
                            // @ts-ignore
                            column.format(value, row)
                          }
                        </span>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        className={'HuntsPagination'}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
