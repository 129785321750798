import { DateTime } from 'luxon';
import { DEFAULT_TIME_ZONE } from 'src/constants/time';

export interface TimeToEnd {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

const useTimeToEnd = (end: DateTime): TimeToEnd => {
  const now = DateTime.now().setZone(DEFAULT_TIME_ZONE);
  const fix = (value) => {
    return value < 10 ? '0' + Math.floor(value) : Math.floor(value).toString();
  };

  var { days, hours, minutes, seconds } = end
    .diff(now, ['days', 'hours', 'minutes', 'seconds'])
    .toObject();

  return { days: fix(days), hours: fix(hours), minutes: fix(minutes), seconds: fix(seconds) };
};

export default useTimeToEnd;
