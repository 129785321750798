import { axiosGet } from 'src/utils/httpClient';

export const authService = {
  fetchTwitchToken: async (code: string) => {
    const response = await axiosGet(`/auth/token?code=${code}`);

    return response;
  },

  handleLogout: () => {
    localStorage.removeItem('bearer');
    localStorage.removeItem('refresh');
    localStorage.removeItem('expiry');
    localStorage.removeItem('twitch');
  },
};

export default authService;
