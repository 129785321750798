import React, { useEffect, useState } from 'react';
import { Challenge, Player, StaticList } from 'src/types';
import { axiosGet, axiosPut } from 'src/utils/httpClient';
import { formatDateYearTime } from 'src/components/Crates';
import Tabs from 'src/components/Tabs/Tabs';

import 'src/assets/CSS/Admin.scss';
import EditBlacklistModal from 'src/components/Admin/modals/EditBlacklistModal';
import AddChallengeModal from 'src/components/Admin/modals/AddChallengeModal';
import ConcludeChallengeModal from 'src/components/Admin/modals/ConcludeChallengeModal';
import Button from 'src/ui/Button';

const ChallengesAdmin = () => {
  const getChallenges = async (): Promise<Array<Challenge>> => axiosGet(`/admin/challenges`);
  const deleteChallenge = async (id: string): Promise<Array<Challenge>> =>
    axiosPut(`/admin/delete-challenge`, { uuid: id });
  const getPlayers = async (): Promise<Array<Player>> => axiosGet(`/admin/players`);
  const getChallengerPrizes = async (): Promise<StaticList> => axiosGet(`/admin/challenger-prizes`);

  const [challenges, setChallenges] = useState<Array<Challenge>>([]);
  const [editing, setEditing] = useState<Challenge | undefined>(undefined);
  const [open, setOpen] = React.useState(false);
  const [openWinner, setOpenWinner] = React.useState(false);
  const [players, setPlayers] = useState<Array<Player>>([]);
  const [prizes, setPrizes] = useState<StaticList>({ strings: '' });
  // const [prizes, setPrizes] = useState<any>({ strings: [] });
  const [openPrizes, setOpenPrizes] = React.useState(false);

  useEffect(() => {
    getPlayers().then((response: Array<Player>) => {
      setPlayers(response);
    });
    getChallengerPrizes().then((response: StaticList) => {
      setPrizes(response);
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
    setOpenWinner(false);
    setEditing(undefined);
  };

  useEffect(() => {
    getChallenges().then((response: Array<Challenge>) => {
      setChallenges(response);
    });
  }, []);

  // const prizeNames = prizes.strings;
  const prizeNames = prizes.strings.split('\n');

  return (
    <div>
      <div className='Section-heading'>
        <h4>Challenges</h4>
        <Button
          onClick={() => setOpen(true)}
          customStyle={{
            padding: '5px',
            borderRadius: '6px',
            fontSize: '16px',
          }}>
          Add Challenge
        </Button>
      </div>

      {/* @ts-ignore */}
      <Tabs>
        {/* @ts-ignore */}
        <div label='Challenges'>
          <div className='Btcs-table'>
            <ul className='Header'>
              <li>Name</li>
              <li>Date</li>
              <li>Action</li>
            </ul>
            {challenges.map((challenge) => {
              return (
                <ul className='Entry'>
                  <li>{challenge.name}</li>
                  <li>{formatDateYearTime(challenge.creationDate)}</li>
                  <li className='Actions'>
                    <button
                      onClick={() => {
                        setEditing(challenge);
                        setOpen(true);
                      }}>
                      Edit
                    </button>

                    {challenge.winnerName ? (
                      <div />
                    ) : (
                      <button
                        onClick={() => {
                          setEditing(challenge);
                          setOpenWinner(true);
                        }}>
                        Set Winner
                      </button>
                    )}

                    <button
                      onClick={() => {
                        deleteChallenge(challenge.id).then((r) => {
                          setChallenges(r);
                        });
                      }}>
                      Delete
                    </button>
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
        {/* @ts-ignore */}
        <div label='Prizes'>
          <div className='Section-heading'>
            <button onClick={() => setOpenPrizes(true)}>Edit</button>
          </div>

          <div className='Blacklist'>
            <ul>
              {prizeNames.map((name) => (
                <li>{name}</li>
              ))}
            </ul>
          </div>
        </div>
      </Tabs>

      <EditBlacklistModal
        key={prizes.strings}
        open={openPrizes}
        onClose={handleClose}
        strings={prizes.strings}
        title={'Edit Challengers Prize List'}
        endpoint={`/admin/modify-challenger-prizes`}
      />

      <AddChallengeModal
        key={editing ? editing.id : 'no-id-challenge'}
        open={open}
        onClose={handleClose}
        editing={editing}
        setItems={setChallenges}
      />

      <ConcludeChallengeModal
        key={editing ? editing.id : 'no-id-challenge'}
        open={openWinner}
        onClose={handleClose}
        editing={editing}
        players={players}
      />
    </div>
  );
};

export default ChallengesAdmin;
