import React, { useEffect } from 'react';

import s from './style.module.scss';
import About from 'src/components/raffles/About';
import RaffleCard from 'src/components/raffles/RaffleCard';
import HowToEarnWpoints from 'src/components/raffles/HowToEarnWpoints';
import { useMutation, useQuery } from '@tanstack/react-query';
import playerService from 'src/services/playerService';
import Hero from 'src/components/raffles/Hero';
import { useNavigate, useSearchParams } from 'react-router';
import { useLocation } from 'react-router';
import { routes } from 'src/routes';
import { toast } from 'react-toastify';
import Pagination from 'src/ui/Pagination';
import usePlayerStore from 'src/store/playerStore';
import { REFETCHING_TIME } from 'src/constants/fetching';
import { RaffleStatus, RaffleType } from 'src/@types/raffles';

const Raffles: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const itemsPerPage = 6;
  const navigate = useNavigate();
  const { updateField } = usePlayerStore();
  const page = Number(searchParams.get('page'));
  const previousRafflesPage = Number(searchParams.get('previousRafflesPage'));
  const upcomingRafflesPage = Number(searchParams.get('upcomingRafflesPage'));
  const currentUrl = `${window.location.origin}${window.location.pathname}${window.location.search}`;

  const {
    data,
    isLoading,
    isError,
    refetch: refetchRaffles,
  } = useQuery({
    queryKey: ['publicCurrentRaffles'],
    queryFn: () =>
      playerService.getRaffles({
        type: RaffleType.RAFFLE,
        status: RaffleStatus.ACTIVE,
        page: page === 0 ? page : page - 1,
        size: itemsPerPage,
      }),
    placeholderData: (previousData) => previousData,
    refetchInterval: REFETCHING_TIME,
  });

  const {
    data: dataUpcoming,
    isLoading: isLoadingUpcoming,
    isError: isErrrorUpcoming,
    refetch: refetchUpcoming,
  } = useQuery({
    queryKey: ['publicUpcomingRaffles'],
    queryFn: () =>
      playerService.getRaffles({
        type: RaffleType.RAFFLE,
        status: RaffleStatus.UPCOMING,
        page: page === 0 ? page : page - 1,
        size: itemsPerPage,
      }),
    placeholderData: (previousData) => previousData,
    refetchInterval: REFETCHING_TIME,
  });

  const {
    data: previousRaffles,
    isLoading: isLoadingPreviousRaffles,
    refetch: refetchPreviousRaffles,
  } = useQuery({
    queryKey: ['previousCurrentRaffles'],
    queryFn: () =>
      playerService.getRaffles({
        type: RaffleType.RAFFLE,
        status: RaffleStatus.COMPLETED,
        page: previousRafflesPage === 0 ? previousRafflesPage : previousRafflesPage - 1,
        size: itemsPerPage,
      }),
    placeholderData: (previousData) => previousData,
    refetchInterval: REFETCHING_TIME,
  });

  const { mutate } = useMutation({
    mutationKey: ['joinToRaffle'],
    mutationFn: (id: string) => playerService.joinToRaffle({ id }),
    onSuccess: () => {
      toast.success('You have successfully joined the raffle');
      navigate(routes.raffles);
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message;
      if (errorMessage.includes('does not have enough coins to join the')) {
        toast.error('Not enough points to participate');
      } else {
        toast.error(errorMessage);
        console.error('Join raffle error:', error);
      }
    },
  });

  const onClickRaffles = (id: string) => {
    navigate(`/raffle/${id}`, { state: { from: currentUrl } });
  };

  const handleChangePage = (
    value: number,
    key: 'page' | 'previousRafflesPage' | 'upcomingRafflesPage',
  ) => {
    setSearchParams((prev) => {
      prev.set(key, value.toString());
      return prev;
    });
  };

  useEffect(() => {
    if (data) {
      updateField('raffles', data.data);
    }
  }, [data]);

  useEffect(() => {
    if (previousRaffles) {
      updateField('previousRaffles', previousRaffles.data);
    }
  }, [previousRaffles]);

  useEffect(() => {
    if (!searchParams.has('page')) {
      setSearchParams((prev) => {
        prev.set('page', '1');
        return prev;
      });
    }

    if (!searchParams.has('upcomingRafflesPage')) {
      setSearchParams((prev) => {
        prev.set('upcomingRafflesPage', '1');
        return prev;
      });
    }

    if (!searchParams.has('previousRafflesPage')) {
      setSearchParams((prev) => {
        prev.set('previousRafflesPage', '1');
        return prev;
      });
    }
  }, [location]);

  useEffect(() => {
    refetchRaffles();
  }, [page]);

  useEffect(() => {
    refetchUpcoming();
  }, [upcomingRafflesPage]);

  useEffect(() => {
    refetchPreviousRaffles();
  }, [previousRafflesPage]);

  return (
    <div className={s.raffles}>
      <Hero />

      <div className={s.raffles__container}>
        <div className='container'>
          <div className={s.raffles__content}>
            <About />

            <div className={s.rafflesBlock}>
              <h4 id='currentRaffles' className={s.raffles__blockTitle}>
                Current raffles
              </h4>

              {isLoading && !data ? (
                <div>Loading...</div>
              ) : data?.total === 0 ? (
                <div className={s.rafflesBlock__notFound}>No Raffles</div>
              ) : (
                <>
                  <div className={s.rafflesBlock__cardList}>
                    {data?.data?.map((raffle) => (
                      <RaffleCard item={raffle} onClick={() => onClickRaffles(raffle.id)} />
                    ))}
                  </div>

                  {data && data.total > itemsPerPage && (
                    <Pagination
                      count={Math.ceil(data.total / itemsPerPage)}
                      itemsPerPage={itemsPerPage}
                      page={page}
                      onChangePage={(e, value) => handleChangePage(value, 'page')}
                    />
                  )}
                </>
              )}
            </div>

            <div className={s.rafflesBlock}>
              <h4 id='previousRaffles' className={s.raffles__blockTitle}>
                Upcoming raffles
              </h4>

              {isLoadingUpcoming && !dataUpcoming ? (
                <div>Loading...</div>
              ) : dataUpcoming?.total === 0 ? (
                <div className={s.rafflesBlock__notFound}>No Raffles</div>
              ) : (
                <>
                  <div className={s.rafflesBlock__cardList}>
                    {dataUpcoming?.data?.map((raffle) => (
                      <RaffleCard item={raffle} onClick={() => onClickRaffles(raffle.id)} />
                    ))}
                  </div>

                  {dataUpcoming && dataUpcoming.total > itemsPerPage && (
                    <Pagination
                      count={Math.ceil(dataUpcoming.total / itemsPerPage)}
                      itemsPerPage={itemsPerPage}
                      page={upcomingRafflesPage}
                      onChangePage={(e, value) => handleChangePage(value, 'upcomingRafflesPage')}
                    />
                  )}
                </>
              )}
            </div>

            <div className={s.rafflesBlock}>
              <h4 id='previousRaffles' className={s.raffles__blockTitle}>
                Previous raffles
              </h4>

              {isLoadingPreviousRaffles && !previousRaffles ? (
                <div>Loading...</div>
              ) : previousRaffles?.total === 0 ? (
                <div className={s.rafflesBlock__notFound}>No Raffles</div>
              ) : (
                <>
                  <div className={s.rafflesBlock__cardList}>
                    {previousRaffles?.data?.map((raffle) => (
                      <RaffleCard item={raffle} onClick={() => onClickRaffles(raffle.id)} />
                    ))}
                  </div>

                  {previousRaffles && previousRaffles.total > itemsPerPage && (
                    <Pagination
                      count={Math.ceil(previousRaffles.total / itemsPerPage)}
                      itemsPerPage={itemsPerPage}
                      page={previousRafflesPage}
                      onChangePage={(e, value) => handleChangePage(value, 'previousRafflesPage')}
                    />
                  )}
                </>
              )}
            </div>

            {/* <RafflesList title='Previous raffles' /> */}
          </div>
        </div>
      </div>

      <div className={s.raffles__content}>
        <HowToEarnWpoints />
      </div>
    </div>
  );
};

export default Raffles;
