import { Avatar, Checkbox, Chip, Dialog, FormControlLabel, Grid } from '@mui/material';
import { useState } from 'react';
import { dialogStyles, ModalProps, useStyles } from 'src/layouts/AdminLayout';
import { ConcludeSlotBattleRequest, ShuffleSlotsGame, SlotBattle } from 'src/types';
import { axiosPut } from 'src/utils/httpClient';

export type SlotBattleModalProps = {
  editing?: SlotBattle;
  setItems: any;
} & ModalProps;

function ConcludeSlotBattleModal(props: SlotBattleModalProps) {
  const classes = useStyles();
  const { onClose, open, setItems } = props;

  const [warned, setWarned] = useState<boolean>(false);
  const [selected, setSelected] = useState<Array<ShuffleSlotsGame>>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const concludeWinner = async (request: ConcludeSlotBattleRequest): Promise<Array<SlotBattle>> =>
    axiosPut(`/moderate/set-battle-winner`, request);

  const handleClose = () => {
    onClose();
  };

  const removeFromSelected = (n: ShuffleSlotsGame) => {
    const alreadyAdded = selected.find((s) => s.name == n.name);
    if (!alreadyAdded) {
      return;
    }
    setSelected(selected.filter((c) => c.name != n.name));
  };

  const addToSelected = (n: ShuffleSlotsGame) => {
    const alreadyAdded = selected.find((s) => s.name == n.name);
    if (alreadyAdded) {
      return;
    }
    const newSelected: any[] = [];
    newSelected.push(n);
    setSelected(newSelected);
  };

  const results: Array<ShuffleSlotsGame> = [];
  if (props.editing) {
    Object.keys(props.editing.slots).map((key) => {
      const data = key.split('::');
      results.push({
        name: data[0],
        image: data[1],
      });
    });
  }

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
      <h2>Conclude Slot Battle</h2>
      <div className='Btcs-form'>
        <div className='Form-input-field'>
          <h6>Winning Slot Game</h6>
          <Grid xs={5} spacing={1}>
            {selected.map((s) => (
              <Chip
                onClick={() => removeFromSelected(s)}
                className={classes.chip}
                avatar={<Avatar alt={s.name} src={s.image} />}
                label={s.name}
                variant='outlined'
              />
            ))}
          </Grid>
        </div>

        <div className='Form-input-field'>
          <div className='Games-search-result'>
            {results!.map((r: ShuffleSlotsGame, i: number) => {
              return (
                <div className='Result-entry'>
                  <ul
                    onClick={() => {
                      addToSelected(r);
                    }}>
                    <li>
                      <span>{i + 1}</span>
                    </li>
                    <li>
                      <img src={r.image} width={25} />
                    </li>
                    <li>
                      <span>{r.name}</span>
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        </div>

        <FormControlLabel
          control={
            <Checkbox name='Warning' checked={warned} onChange={(e) => setWarned(!warned)} />
          }
          label='I understand that this action cannot be undone.'
        />

        <div className='Form-submission'>
          <button
            className={warned && selected.length == 1 ? '' : 'Alt'}
            onClick={() => {
              if (submitting) {
                return;
              }
              if (selected.length != 1 || !warned) {
                return;
              }
              setSubmitting(true);
              if (props.editing) {
                concludeWinner({
                  id: props.editing.id,
                  game: selected[0].name,
                }).then((r) => {
                  setWarned(false);
                  setSelected([]);
                  setSubmitting(false);
                  handleClose();
                  setItems(r);
                });
              }
            }}>
            End Battle & Reward Players
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default ConcludeSlotBattleModal;
