import React, { useEffect, useState } from 'react';

import s from './style.module.scss';
import Pagination from 'src/ui/Pagination';
import GiveawayCard from '../GiveawayCard';
import { useNavigate, useSearchParams } from 'react-router';
import { useLocation } from 'react-router';
import playerService from 'src/services/playerService';
import { useQuery } from '@tanstack/react-query';
import usePlayerStore from 'src/store/playerStore';
import { REFETCHING_TIME } from 'src/constants/fetching';
import { RaffleCategory, RaffleStatus, RaffleType } from 'src/@types/raffles';

const GiveawaysList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { giveaways, updateField } = usePlayerStore();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const itemsPerPage = 6;
  const location = useLocation();
  const page = Number(searchParams.get('page'));
  const upcomingPage = Number(searchParams.get('upcomingPage'));
  // const category = searchParams.get('type') || 'Shuffle';
  const currentUrl = `${window.location.origin}${window.location.pathname}${window.location.search}`;

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['publicCurrentGiveaways'],
    queryFn: () =>
      playerService.getRaffles({
        type: RaffleType.GIVEAWAY,
        status: RaffleStatus.ACTIVE,
        page: page === 0 ? page : page - 1,
        size: itemsPerPage,
        // category: category === 'Shuffle' ? RaffleCategory.SHUFFLE : RaffleCategory.RUNESCAPE,
      }),
    placeholderData: (previousData) => previousData,
    refetchInterval: REFETCHING_TIME,
  });

  const {
    data: dataUpcoming,
    error: errorUpcoming,
    isLoading: isLoadingUpcoming,
    refetch: refetchUpcoming,
  } = useQuery({
    queryKey: ['publicUpcomingGiveaways'],
    queryFn: () =>
      playerService.getRaffles({
        type: RaffleType.GIVEAWAY,
        status: RaffleStatus.UPCOMING,
        page: upcomingPage === 0 ? upcomingPage : upcomingPage - 1,
        size: itemsPerPage,
        // category: category === 'Shuffle' ? RaffleCategory.SHUFFLE : RaffleCategory.RUNESCAPE,
      }),
    placeholderData: (previousData) => previousData,
    refetchInterval: REFETCHING_TIME,
  });

  useEffect(() => {
    if (data) {
      updateField('giveaways', data.data);
    }
  }, [data]);

  const onClickGiveaway = (id: string) => {
    navigate(`/giveaway/${id}`, { state: { from: currentUrl } });
  };

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    refetchUpcoming();
  }, [upcomingPage]);

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!searchParams.has('page')) {
      setSearchParams((prev) => {
        prev.set('page', '1');
        return prev;
      });
    }

    if (!searchParams.has('upcomingPage')) {
      setSearchParams((prev) => {
        prev.set('upcomingPage', '1');
        return prev;
      });
    }

    // if (!searchParams.has('type')) {
    //   setSearchParams((prev) => {
    //     prev.set('type', 'Shuffle');
    //     return prev;
    //   });
    // }
  }, [location]);

  // useEffect(() => {
  //   refetch();
  // }, [category]);

  const handleChangePage = (value: number) => {
    setSearchParams((prev) => {
      prev.set('page', value.toString());
      return prev;
    });
  };

  const handleChangeUpcomingPage = (value: number) => {
    setSearchParams((prev) => {
      prev.set('upcomingPage', value.toString());
      return prev;
    });
  };

  return (
    <>
      <div className={s.rafflesBlock}>
        <h4 className={s.rafflesBlock__title}>Active giveaways</h4>

        <div className={s.rafflesBlock__listBlock_desktop}>
          {isLoading && !giveaways ? (
            <div>Loading...</div>
          ) : giveaways.length === 0 ? (
            <div className={s.rafflesBlock__notFound}>No Giveaways</div>
          ) : (
            <>
              <div className={s.rafflesBlock__cardList}>
                {giveaways?.map((raffle) => (
                  <GiveawayCard item={raffle} onClick={() => onClickGiveaway(raffle.id)} />
                ))}
              </div>

              {data && data?.total > itemsPerPage && (
                <Pagination
                  count={Math.ceil(data.total / itemsPerPage)}
                  itemsPerPage={itemsPerPage}
                  page={page}
                  onChangePage={(e, value) => handleChangePage(value)}
                />
              )}
            </>
          )}
        </div>
      </div>

      <div className={s.rafflesBlock}>
        <h4 className={s.rafflesBlock__title}>Upcoming giveaways</h4>

        <div className={s.rafflesBlock__listBlock_desktop}>
          {/* @ts-ignore */}
          {isLoadingUpcoming && !dataUpcoming?.data ? (
            <div>Loading...</div>
          ) : dataUpcoming?.total === 0 ? (
            <div className={s.rafflesBlock__notFound}>No Giveaways</div>
          ) : (
            <>
              <div className={s.rafflesBlock__cardList}>
                {dataUpcoming?.data?.map((raffle) => (
                  <GiveawayCard item={raffle} onClick={() => onClickGiveaway(raffle.id)} />
                ))}
              </div>

              {dataUpcoming && dataUpcoming?.total > itemsPerPage && (
                <Pagination
                  count={Math.ceil(dataUpcoming.total / itemsPerPage)}
                  itemsPerPage={itemsPerPage}
                  page={upcomingPage}
                  onChangePage={(e, value) => handleChangeUpcomingPage(value)}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GiveawaysList;
