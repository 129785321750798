import { Dialog } from '@mui/material';
import React from 'react';

import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';

import s from './style.module.scss';
import Button from '../Button';

const dialogStyles = {
  backgroundColor: '#333A53',
  color: 'white',
  padding: '25px',
  borderRadius: '24px',
  boxShadow: 'none',
  // width: '600px',
};

interface IModalProps {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  isCloseIcon?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const Modal = ({ open, handleClose, children, isCloseIcon, maxWidth = 'lg' }: IModalProps) => {
  return (
    <Dialog
      className={s.modal}
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={maxWidth}
      PaperProps={{ style: dialogStyles }}>
      {isCloseIcon && (
        <Button isClear className={s.modal__close} onClick={handleClose}>
          <CloseIcon />
        </Button>
      )}

      {children}
    </Dialog>
  );
};

export default Modal;
