import React, { useEffect, useState } from 'react';
import '../assets/CSS/Highlights.scss';
import '../assets/CSS/Highlights.responsive.scss';
import { FeaturedVideo, HighlightsApi } from '../types';
import { axiosGet } from '../utils/httpClient';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { MenuProps } from './Hunts';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
// import {makeStyles} from "@material-ui/core/styles";
import { makeStyles } from '@mui/styles';
import { VideoCarousel } from 'src/components/VideoCarousel';
import { StreamersVideos } from 'src/components/StreamersVideos';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  card: {
    maxWidth: 300,
    height: '500px',
    backgroundColor: 'transparent !important',
    margin: '25px',
    paddingTop: '15px',
    border: '1px solid #2a2e38',
    boxShadow: 'none',
  },
  cardMedia: {
    height: '280px',
    padding: '0',
    marginTop: '-20px',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  cardContent: {
    background: 'linear-gradient(180deg, #041128, #1e1826)',
    height: '200px',
    borderTop: '1px solid #2d007d',
    textAlign: 'left',
    fontSize: '11px !important',
    color: '#cecece',
  },
  select: {
    '&:before': {
      borderColor: 'white !important',
    },
    '&:after': {
      borderColor: 'white !important',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white !important',
    },
    color: 'white !important',
    background: '#2c303e !important',
  },
  icon: {
    fill: 'white !important',
  },
  accordion: {
    background: 'linear-gradient(180deg, #041128, #1e1826) !important',
    borderRadius: '10px',
    color: 'white !important',
  },
}));

function highlightContainer(featuredVideo: FeaturedVideo, first: boolean): JSX.Element {
  if (featuredVideo) {
    return (
      <div className='Multiplier-highlight-clip'>
        <div className={'Clip' + (first ? ' First' : '')}>
          <video controls src={featuredVideo.url} />
        </div>
        {/*<span className="Unpadded"><span className="Unpadded Highlight">{featuredVideo.uploader}</span></span>*/}
        {/*<span className="Unpadded">{featuredVideo.game} <span className="Unpadded Highlight">{featuredVideo.multiplier}x</span></span>*/}
        {/*<br /><br /><br /><br /><br />*/}
      </div>
    );
  }
  return <span></span>;
}

function extractUrl(fullyQualified: string): string {
  return fullyQualified.split('?v=')[1];
}

function youtubeHighlightContainer(
  featuredVideo: FeaturedVideo,
  highlight: boolean = true,
): JSX.Element {
  if (featuredVideo) {
    return (
      <div className='Multiplier-highlight-clip'>
        <div className={'Clip First '}>
          <div className='video-container'>
            <iframe
              src={`https://www.youtube.com/embed/${extractUrl(featuredVideo.url)}`}
              frameBorder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope;'
              allowFullScreen></iframe>
          </div>
        </div>
        {/*<span className="Unpadded"><span className="Unpadded Highlight">{featuredVideo.uploader}</span></span>*/}
        {/*<span className="Unpadded">{featuredVideo.game} <span className="Unpadded Highlight">{featuredVideo.multiplier}x</span></span>*/}
        {/*<br /><br /><br /><br /><br />*/}
      </div>
    );
  }
  return <span></span>;
}

const getHighlights = async (): Promise<HighlightsApi> => axiosGet(`/general/highlight-clips`);

export default function Highlights() {
  const HighlightsMobileFilter = (
    highlightFilter: string,
    setHighlightFilter: (filter: any) => any,
  ) => {
    const classes = useStyles();

    const handleChange = (event: any) => {
      const {
        target: { value },
      } = event;
      setHighlightsFilter(value);
    };

    const snapshots = ['1st Place', '2nd Place', '3rd Place'];
    return (
      <div className='Leaderboard-filter'>
        <div className='Board-filter'>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id='demo-multiple-checkbox-label' sx={{ color: 'white' }}>
              View
            </InputLabel>
            <Select
              labelId='demo-multiple-checkbox-label'
              className={classes.select}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  root: classes.root,
                },
              }}
              id='demo-multiple-checkbox'
              value={highlightFilter}
              onChange={handleChange}
              sx={{
                color: 'white',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#2a2e38',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#2a2e38',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#2a2e38',
                },
                '.MuiSvgIcon-root ': {
                  fill: 'white !important',
                },
              }}
              input={<OutlinedInput sx={{ color: 'white' }} label='View' />}
              renderValue={(selected) => selected}
              MenuProps={MenuProps}>
              {snapshots.map((variant) => (
                //@ts-ignore
                <MenuItem key={variant.id} value={variant}>
                  <ListItemText primary={variant} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    );
  };

  const [data, setData] = React.useState<HighlightsApi | undefined>(undefined);
  const [highlightsFilter, setHighlightsFilter] = useState<string>('1st Place');
  const [currentHighlight, setCurrentHighlight] = useState<number>(0);
  const [currentViewerBuy, setCurrentViewerBuy] = useState<number>(0);

  useEffect(() => {
    getHighlights().then((r) => setData(r));
  }, []);

  const videos: Array<JSX.Element> = [];

  if (data) {
    if (data.topWinHighlights) {
      data.topWinHighlights.map((h) => {
        videos.push(youtubeHighlightContainer(h));
      });
    }
  }
  // const viewing = highlightsFilter == '1st Place' ? topWin : highlightsFilter == '2nd Place' ? secondWin : thirdWin

  return (
    <>
      <div className='App-contents Challenges VIP'>
        <div className='Landing-content'>
          <br />
          <br />
          <div className='Landing-heading'>
            <h2 className='Main-heading Main-heading-larger'>Highlights</h2>
            <div className='Heading-divider' />
          </div>
          <div className='App-highlights-reel App-highlights-reel-desktop'>
            <VideoCarousel videos={videos} />
          </div>
          <div className='App-highlights-reel-mobile'>
            {data && data.topWinHighlights.length > 0 ? (
              youtubeHighlightContainer(data.topWinHighlights[currentHighlight], false)
            ) : (
              <span>No Highlight for this week yet :(</span>
            )}
            <div className='Highlight-mobile-pagination'>
              <div
                className={currentHighlight == 0 ? 'Active' : ''}
                onClick={() => setCurrentHighlight(0)}
              />
              <div
                className={currentHighlight == 1 ? 'Active' : ''}
                onClick={() => setCurrentHighlight(1)}
              />
              <div
                className={currentHighlight == 2 ? 'Active' : ''}
                onClick={() => setCurrentHighlight(2)}
              />
              <div
                className={currentHighlight == 3 ? 'Active' : ''}
                onClick={() => setCurrentHighlight(3)}
              />
              <div
                className={currentHighlight == 4 ? 'Active' : ''}
                onClick={() => setCurrentHighlight(4)}
              />
              <div
                className={currentHighlight == 5 ? 'Active' : ''}
                onClick={() => setCurrentHighlight(5)}
              />
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className='Landing-heading'>
            <h2 className='Main-heading'>Top Viewer Buys</h2>
            <div className='Heading-divider' />
          </div>
          <div className='Top-Viewer-Buys-container App-highlights-reel-desktop'>
            {data && data.topViewerBuys ? (
              <div className='Top-Viewer-Buys'>
                <div
                  className='Top-Viewer-Buy'
                  style={{ backgroundImage: `url('${data.topViewerBuys[1].thumbnail}')` }}
                  onClick={() => (window.location.href = data.topViewerBuys[1].url)}></div>
                <div
                  className='Top-Viewer-Buy Top-Viewer-Buy-Main'
                  style={{ backgroundImage: `url('${data.topViewerBuys[0].thumbnail}')` }}
                  onClick={() => (window.location.href = data.topViewerBuys[0].url)}></div>
                <div
                  className='Top-Viewer-Buy'
                  style={{ backgroundImage: `url('${data.topViewerBuys[2].thumbnail}')` }}
                  onClick={() => (window.location.href = data.topViewerBuys[2].url)}></div>
              </div>
            ) : (
              <div />
            )}
          </div>
          <div className='App-highlights-reel-mobile'>
            {data && data.topViewerBuys.length > 0 ? (
              youtubeHighlightContainer(data.topViewerBuys[currentViewerBuy], false)
            ) : (
              <span>No Highlight for this week yet :(</span>
            )}
            <div className='Highlight-mobile-pagination'>
              <div
                className={currentViewerBuy == 0 ? 'Active' : ''}
                onClick={() => setCurrentViewerBuy(0)}
              />
              <div
                className={currentViewerBuy == 1 ? 'Active' : ''}
                onClick={() => setCurrentViewerBuy(1)}
              />
              <div
                className={currentViewerBuy == 2 ? 'Active' : ''}
                onClick={() => setCurrentViewerBuy(2)}
              />
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className='Landing-heading'>
            <h2 className='Main-heading'>Our Latest Videos</h2>
            <div className='Heading-divider' />
          </div>
          <StreamersVideos />
        </div>
      </div>
    </>
  );
}
