import React from 'react';
import s from './style.module.scss';

import Button from 'src/ui/Button';
import { Link } from 'react-router';
import PlatformIcon from 'src/components/PlatformIcon';

interface IRaffleRequirementsProps {
  title: string;
  desctiption?: string;
  links: string[];
}

const RaffleSubscriptionRequirements = ({
  title,
  desctiption,
  links,
}: IRaffleRequirementsProps) => {
  return (
    <div className={s.block}>
      {title && <div className={s.block__title}>{title}</div>}

      {desctiption ? (
        <div className={s.block__desc}>{desctiption}</div>
      ) : (
        <div className={s.block__desc}>
          To participate in the draw, subscribe to social networks
        </div>
      )}

      <div className={s.block__links}>
        {links.map((link, index) => (
          <Link to={link} key={index} target='_blank'>
            <Button className={s.block__btn} isIcon>
              <PlatformIcon url={link} />
            </Button>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RaffleSubscriptionRequirements;
