import { Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { dialogStyles, ModalProps } from 'src/layouts/AdminLayout';
import { CreateStoreItemRequest, EditStoreItemRequest, ItemDefinition, StoreItem } from 'src/types';
import { axiosPost, axiosPut } from 'src/utils/httpClient';

export type StoreItemModalProps = {
  items: Array<ItemDefinition>;
  editing?: StoreItem;
  setItems: any;
} & ModalProps;

function AddStoreItemModal(props: StoreItemModalProps) {
  const { onClose, open, setItems } = props;

  const [item, setItem] = useState<number>(1);
  const [cost, setCost] = useState<number | undefined>(undefined);
  const [quantity, setQuantity] = useState<number | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const createStoreItem = async (request: CreateStoreItemRequest): Promise<StoreItem> =>
    axiosPost(`/admin/create-store-item`, request);
  const editStoreItem = async (request: EditStoreItemRequest): Promise<Array<StoreItem>> =>
    axiosPut(`/admin/edit-store-item`, request);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (props.editing) {
      setItem(props.editing.definition!.id);
      setCost(props.editing.cost);
      setQuantity(props.editing.quantity);
    }
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
      <h2>{props.editing ? 'Edit' : 'Add'} Store Item</h2>
      <div className='Btcs-form'>
        <div className='Form-input-field'>
          <h6>Item</h6>
          <div className='Form-input'>
            <select defaultValue={item} onChange={(e) => setItem(Number(`${e.target.value}`))}>
              {/*<option></option>*/}
              {props.items.map((item) => {
                return <option value={item.id}>{item.name}</option>;
              })}
            </select>
          </div>
        </div>
        <div className='Form-input-field'>
          <h6>Cost</h6>
          <div className='Form-input'>
            <input
              type='number'
              placeholder='Item Cost...'
              defaultValue={cost}
              onChange={(e) => {
                setCost(Number(e.target.value));
              }}
            />
          </div>
        </div>
        <div className='Form-input-field'>
          <h6>Quantity</h6>
          <div className='Form-input'>
            <input
              type='number'
              placeholder='Item Quantity...'
              defaultValue={quantity}
              onChange={(e) => {
                setQuantity(Number(e.target.value));
              }}
            />
          </div>
        </div>
        <div className='Form-submission'>
          <button
            className='Alt'
            onClick={() => {
              if (submitting) {
                return;
              }
              if (!item || !cost || !quantity) {
                return;
              }
              setSubmitting(true);
              if (props.editing) {
                editStoreItem({
                  id: props.editing.id,
                  itemId: item,
                  cost: cost,
                  quantity: quantity,
                }).then((response) => {
                  setSubmitting(false);
                  setItem(-1);
                  setCost(undefined);
                  setQuantity(undefined);
                  setItems(response);
                  onClose();
                });
              } else {
                createStoreItem({
                  itemId: item,
                  cost: cost,
                  quantity: quantity,
                }).then((response) => {
                  setSubmitting(false);
                  setItem(-1);
                  setCost(undefined);
                  setQuantity(undefined);
                  setItems(response);
                  onClose();
                });
              }
            }}>
            {submitting ? 'Submitting...' : (props.editing ? 'Save' : 'Add') + ' Item'}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default AddStoreItemModal;
