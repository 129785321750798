import { ReactComponent as GiftIcon } from 'src/assets/icons/gift.svg';
import RaffleEndCounter from 'src/components/EndCounter';
import RaffleParticipants from 'src/components/raffles/RaffleParticipants';
import Button from 'src/ui/Button';

import BTCsPurpleCoinShadowImage from 'src/assets/images/BTCsPurpleCoinShadow.png';

import s from './style.module.scss';
import StatusBadge, { Status } from 'src/components/StatusBadge';
import useAuthStore from 'src/store/authStore';
import useModal from 'src/hooks/useModal';
import Modal from 'src/ui/Modal';
import LoginWarning from 'src/components/LoginWarning';
import RaffleSubscriptionRequirements from '../RaffleSubscriptionRequirements';
import RaffleBetRequirements from 'src/components/raffles/RaffleBetRequirements';
import { createImageUrl, createPublicImageUrl } from 'src/utils/url';
import { IRaffle, RaffleStatus } from 'src/@types/raffles';

interface IRaffleDetailsCardProps {
  item: IRaffle;
  isAdmin?: boolean;
  isUserJoined?: boolean;
  onJoinClick?: () => void;
}

const RaffleDetailsCard = ({
  item,
  isAdmin,
  isUserJoined,
  onJoinClick,
}: IRaffleDetailsCardProps) => {
  const { user, userSettings } = useAuthStore();
  const { isOpen, openModal, closeModal } = useModal();
  const isUserLoggedIn = user !== null;

  const handleJoinClick = () => {
    if (!isUserLoggedIn) {
      // show login modal
      openModal();
    } else {
      if (onJoinClick) onJoinClick();
    }
  };

  const getStatusForBadge = (status: RaffleStatus): Status => {
    return status.toLowerCase() as Status;
  };

  const formImageUrl = () => {
    if (isAdmin && item.image) {
      return createImageUrl(item.image?.url);
    } else if (!isAdmin && item.imageId) {
      return createPublicImageUrl(item?.imageId);
    } else {
      return BTCsPurpleCoinShadowImage;
    }
  };

  return (
    <>
      <div className={s.card}>
        <div className={s.card__block1}>
          <div className={s.card__img}>
            <img src={formImageUrl()} alt='coin' />
          </div>

          <div className={s.card__info}>
            <StatusBadge status={getStatusForBadge(item.status)} title={item.status} />

            <div className={s.card__title}>{item.name}</div>
            <div className={s.card__desc}>{item.description}</div>
          </div>

          <div className={s.card__prize}>
            <GiftIcon />
            {item.rewardAmount} <span>per winner</span>
          </div>
        </div>

        <div className={s.card__divider} />

        <div className={s.card__block2}>
          <RaffleParticipants
            title='Participants'
            winners={item.numberOfWinners}
            maxWinners={item.maxWinners}
            participants={item.numberOfPlayers}
            maxParticipants={item.maxPlayers}
          />
          <RaffleEndCounter title='End Date' endDate={item.endDate} />
        </div>

        <div className={s.card__divider} />

        <div className={s.card__block3}>
          {item.joinRequirementsType === 'BET' && (
            <RaffleBetRequirements betAmount={item.meta.betAmount} title='Requirements' />
          )}

          {item.joinRequirementsType === 'SUBSCRIPTION' && (
            <RaffleSubscriptionRequirements
              desctiption={item.meta.description}
              links={item.meta.requiredSubscriptions}
              title='Requirements'
            />
          )}

          {!isAdmin && !isUserJoined && item.status === RaffleStatus.ACTIVE && (
            <Button onClick={handleJoinClick}>Join</Button>
          )}
        </div>
      </div>

      <Modal maxWidth='sm' isCloseIcon open={isOpen} handleClose={closeModal}>
        <LoginWarning />
      </Modal>
    </>
  );
};

export default RaffleDetailsCard;
