import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import '../assets/CSS/Settings.scss';
import { IntegrationVerification, PlayerDetails, PlayerSettingsApi } from '../types';
import { axiosGet, axiosPost } from '../utils/httpClient';
import CheckIcon from '@mui/icons-material/Check';
import { CopyAll } from '@mui/icons-material';
import moment from 'moment';
import { dialogStyles, ModalProps } from 'src/layouts/AdminLayout';
import useAuthStore from 'src/store/authStore';
import { useQuery } from '@tanstack/react-query';
import userService from 'src/services/userService';
import Button from 'src/ui/Button';

type ConnectModalProps = {} & ModalProps;

const getKickIntegration = async (): Promise<IntegrationVerification> =>
  axiosGet(`/player/verify-kick`);

function ConnectKickModal(props: ConnectModalProps) {
  const [verification, setVerification] = useState<IntegrationVerification | undefined>(undefined);
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open && !verification) {
      getKickIntegration().then((r) => {
        setVerification(r);
      });
    }
  }, [open]);

  if (!verification) {
    return (
      <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
        <div className='ConnectModal'>
          <h2>Loading...</h2>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
      <div className='ConnectModal'>
        <h2>
          <img src='/profile/kick.svg' />
          Connect Kick Account
        </h2>
        <br />
        <br />

        <span>Your Verification Code:</span>
        <br />
        <div className='CodeField'>
          <input type='text' defaultValue={verification.code} disabled={true} />
          <div className='Ico'>
            <CopyAll />
          </div>
        </div>

        <ol>
          <li>
            Go to our verification{' '}
            <a href='https://kick.com/btcs' target='_action'>
              <span className='Highlight'>Kick Channel</span>
            </a>
          </li>
          <li>
            Type in the following command:{' '}
            <span className='Highlight'>!verify {verification.code}</span>
          </li>
        </ol>
        <span>Your Verification Code becomes invalid in 5 minutes.</span>
        <br />
        <br />
        <br />
        <br />
        <button>Close</button>
      </div>
    </Dialog>
  );
}

export type SettingsModalProps = {
  details?: PlayerDetails;
} & ModalProps;

const getProfileSettings = async (): Promise<PlayerSettingsApi> => axiosGet(`/player/settings`);
const setProfileSettings = async (request: PlayerSettingsApi): Promise<PlayerSettingsApi> =>
  axiosPost(`/player/settings`, request);

export default function SettingsModal(props: SettingsModalProps) {
  const { data } = useQuery({
    queryKey: ['user-settings'],
    queryFn: userService.getUserSettings,
    // retry: 0,
  });

  const { onClose, open, details } = props;
  const { updateField } = useAuthStore();

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [profile, setProfile] = useState<PlayerSettingsApi | undefined>(undefined);

  /** General Settings */
  const [steamTradeUrl, setSteamTradeUrl] = useState<string | undefined>(undefined);
  const [ethAddress, setEthAddress] = useState<string | undefined>(undefined);
  const [btcAddress, setBtcAddress] = useState<string | undefined>(undefined);
  const [shuffleUsername, setShuffleUsername] = useState<string | undefined>(undefined);
  const [updatingDetails, setUpdatingDetails] = useState<boolean>(false);

  /** Connected Accounts */
  const [discordConnected, setDiscordConnected] = useState<boolean>(false);
  const [kickConnected, setKickConnected] = useState<boolean>(false);
  const [openKick, setOpenKick] = React.useState(false);

  /** Validations */
  const [ethValidity, setEthValidity] = useState<number>(0);
  const [btcValidity, setBtcValidity] = useState<number>(0);
  const [shuffleValidity, setShuffleValidity] = useState<number>(0);

  const dialogStyles = {
    backgroundColor: '#1a1d23',
    color: 'white',
    padding: '25px',
    boxShadow: 'none',
    width: '600px',
    overflow: 'hidden',
  };

  const handleClose = () => {
    onClose();
  };

  const validateEth = () => {
    const regex = new RegExp(/^(0x)?[0-9a-fA-F]{40}$/);

    if (ethAddress == null) {
      setEthValidity(2);
    }
    if (regex.test(ethAddress!) == true) {
      setEthValidity(1);
    } else {
      setEthValidity(2);
    }
  };

  const validateBtc = () => {
    const regex = new RegExp(/^(bc1|[13])[a-km-zA-HJ-NP-Z1-9]{25,34}$/);

    if (btcAddress == null) {
      setBtcValidity(2);
    }
    if (regex.test(btcAddress!) == true) {
      setBtcValidity(1);
    } else {
      setBtcValidity(2);
    }
  };

  const getCurrentTabContents = () => {
    switch (currentTab) {
      default:
        return <></>;
      case 0: {
        const joinDate = 'Joined: ' + (profile?.joinDate ?? '-');
        const email = 'Email: ' + (profile?.emailAddress ?? 'N/A');
        const discord = 'Discord: ' + (profile?.discordName ?? 'N/A');
        const kick = 'Kick: ' + (profile?.kickName ?? 'N/A');

        const ethSelector =
          ethValidity == 0 ? '' : ethValidity == 1 ? 'Valid-field' : 'Invalid-field';
        const btcSelector =
          btcValidity == 0 ? '' : btcValidity == 1 ? 'Valid-field' : 'Invalid-field';
        return (
          <>
            <div className='Profile-Username'>
              <h3>
                {details?.username} <span>({profile?.id})</span>
              </h3>
            </div>
            <div className='Profile-Details'>
              <div className='Profile-image'>
                <img src={details?.profilePicture} />
              </div>
              <div className='Profile-values'>
                <ul>
                  <li>
                    <span>{joinDate}</span>
                  </li>
                  <li>
                    <span>{email}</span>
                  </li>
                  <li>
                    <span>{discord}</span>
                  </li>
                  <li>
                    <span>{kick}</span>
                  </li>
                </ul>
              </div>
              <div className='Profile-points'>
                <div className='Points-container'>
                  <span>Points</span>
                  <img src='https://btcs.gg/currency.svg' />
                  <span>{profile?.currentPoints!.toLocaleString('en-US')}</span>
                </div>
              </div>
            </div>

            <div className='Profile-raffles-and-points'>
              <div className='Profile-settings-metric'>
                <div className='Icon'>
                  <img
                    style={{ marginTop: '10px' }}
                    src='https://btcs.gg/vip-img/april-raffle-ticket.png'
                  />
                </div>
                <div className='Value'>
                  <span>Raffles Won</span>
                  <span>{profile?.rafflesWon ?? '-'}</span>
                </div>
              </div>

              <div className='Profile-settings-metric'>
                <div className='Icon'>
                  <img src='https://btcs.gg/currency.svg' />
                </div>
                <div className='Value'>
                  <span>Watch Time</span>
                  <span>{moment.utc(profile?.watchTime ?? 0).format('HH:mm:ss')}</span>
                </div>
              </div>

              <div className='Profile-settings-metric'>
                <div className='Icon'>
                  <img src='https://btcs.gg/currency.svg' />
                </div>
                <div className='Value'>
                  <span>Points Earned</span>
                  <span>{profile?.pointsEarned ?? '-'}</span>
                </div>
              </div>
            </div>

            <h3 className='Field-title'>ETH ADDRESS</h3>

            <div className='Form-input-field'>
              <div className={'Form-input ' + ethSelector}>
                <div className='Form-icon'>
                  <img src='/profile/eth.svg' />
                </div>
                <input
                  type='text'
                  placeholder='ETH Address...'
                  defaultValue={ethAddress}
                  onChange={(e) => {
                    setEthAddress(e.target.value);
                  }}
                />
                {/*<h5 onClick={() => validateEth()}>Verify</h5>*/}
              </div>
            </div>

            <h3 className='Field-title'>BTC ADDRESS</h3>
            <div className='Form-input-field'>
              <div className={'Form-input ' + btcSelector}>
                <div className='Form-icon'>
                  <img src='/navigation/btc.svg' />
                </div>
                <input
                  type='text'
                  placeholder='BTC Address...'
                  defaultValue={btcAddress}
                  onChange={(e) => {
                    setBtcAddress(e.target.value);
                  }}
                />
                {/*<h5 onClick={() => validateBtc()}>Verify</h5>*/}
              </div>
            </div>

            <h3 className='Field-title'>Shuffle Username</h3>

            <div className='Form-input-field'>
              <div className='Form-input'>
                <div className='Form-icon'>
                  <img src='/profile/shuffle.png' />
                </div>
                <input
                  type='text'
                  placeholder='Shuffle Username...'
                  defaultValue={shuffleUsername}
                  onChange={(e) => {
                    setShuffleUsername(e.target.value);
                  }}
                />
                <h5>Verify</h5>
              </div>
            </div>
            <div className='Form-submission'>
              <Button
                borderRadius='6px'
                onClick={() => {
                  if (updatingDetails) {
                    return;
                  }
                  const newSteamTradeUrl = steamTradeUrl ?? '';
                  const newEthAddress = ethAddress ?? '';
                  const newBtcAddress = btcAddress ?? '';
                  const newShuffleUsername = shuffleUsername ?? '';
                  setUpdatingDetails(true);
                  validateEth();
                  validateBtc();
                  setProfileSettings({
                    steamTradeUrl: newSteamTradeUrl,
                    ethWalletAddress: newEthAddress,
                    btcWalletAddress: newBtcAddress,
                    shuffleUsername: newShuffleUsername,
                    kickConnected: false,
                    discordConnected: false,
                  }).then((response) => {
                    setUpdatingDetails(false);
                    setProfile(response);
                  });
                }}>
                {updatingDetails ? 'Updating...' : 'Update Profile'}
              </Button>
            </div>

            <br />
          </>
        );
      }
      case 2: {
        return (
          <>
            <div className='Account-settings-container' style={{ width: 'calc(100% - 20px)' }}>
              <div className='Account-integrations'>
                <div className='Integrations-list'>
                  {discordConnected ? (
                    <div className='Integration-option'>
                      <div className='Integration Connected'>
                        <img src='/profile/discord.svg' />
                        <div className='Title'>
                          <span>Discord</span>
                        </div>
                        <CheckIcon />
                      </div>
                      <div className='Integration-heading'>
                        <span>Connected</span>
                      </div>
                    </div>
                  ) : (
                    <div
                      className='Integration-option'
                      onClick={() => {
                        localStorage.setItem(
                          'discord_integration',
                          `${new Date().getTime() + 60_000}`,
                        );
                        window.location.href =
                          'https://discord.com/oauth2/authorize?client_id=1231647510033072219&redirect_uri=https://btcs.gg/&response_type=code&scope=identify';
                      }}>
                      <div className='Integration'>
                        <img src='/profile/discord.svg' />
                        <div className='Title'>
                          <span>Discord</span>
                        </div>
                      </div>
                      <div className='Integration-heading'>
                        <span>Not Connected</span>
                      </div>
                    </div>
                  )}
                  {kickConnected ? (
                    <div className='Integration-option'>
                      <div className='Integration Connected'>
                        <img src='/profile/kick.svg' />
                        <div className='Title'>
                          <span>Kick</span>
                          <CheckIcon />
                        </div>
                      </div>
                      <div className='Integration-heading'>
                        <span>Connected</span>
                      </div>
                    </div>
                  ) : (
                    <div className='Integration-option' onClick={() => setOpenKick(true)}>
                      <div className='Integration'>
                        <img src='/profile/kick.svg' />
                        <div className='Title'>
                          <span>Kick</span>
                        </div>
                      </div>
                      <div className='Integration-heading'>
                        <span>Not Connected</span>
                      </div>
                    </div>
                  )}
                </div>
                <ConnectKickModal
                  key={'integrate-kick'}
                  open={openKick}
                  onClose={() => setOpenKick(false)}
                />
              </div>
            </div>
          </>
        );
      }
    }
  };

  useEffect(() => {
    if (data) {
      setProfile(data);
      setSteamTradeUrl(data.steamTradeUrl);
      setEthAddress(data.ethWalletAddress);
      setBtcAddress(data.btcWalletAddress);
      setShuffleUsername(data.shuffleUsername);
      setKickConnected(data.kickConnected);
      setDiscordConnected(data.discordConnected);
    }
  }, [data]);

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
      <div className='Btcs-form Settings-Modal'>
        <h2>Settings</h2>
        <br />
        <ul className='Tabbing'>
          <li className={currentTab == 0 ? 'Active' : ''} onClick={() => setCurrentTab(0)}>
            Main
          </li>
          <li className={currentTab == 1 ? 'Active' : ''} onClick={() => setCurrentTab(1)}>
            Raffles
          </li>
          <li className={currentTab == 2 ? 'Active' : ''} onClick={() => setCurrentTab(2)}>
            Connections
          </li>
        </ul>
        <br />
        {getCurrentTabContents()}
      </div>
    </Dialog>
  );
}
