import React from 'react';
import s from './style.module.scss';
import cn from 'classnames';

interface IInputProps {
  label?: string;
  type?: string;
  name?: string;
  min?: number;
  placeholder?: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  error?: string;
}

const Input = ({
  label,
  type = 'text',
  name,
  min,
  placeholder,
  value,
  onChange,
  className,
  error,
}: IInputProps) => {
  const isError = !!error;

  return (
    <div className={cn(s.input, className)}>
      {label && <label className={s.input__label}>{label}</label>}
      <input
        className={cn(s.input__field, className)}
        type={type}
        name={name}
        placeholder={placeholder}
        min={min}
        value={value}
        onChange={onChange}
      />

      {isError && <div className={s.input__error}>{error}</div>}
    </div>
  );
};

export default Input;
