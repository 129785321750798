import s from './style.module.scss';

interface RaffleAboutCardProps {
  icon: string | React.ReactNode;
  title: string;
  text: string;
}

const RaffleAboutCard = ({ icon, title, text }: RaffleAboutCardProps) => {
  return (
    <div className={s.card}>
      <div className={s.card__titleBlock}>
        <div className={s.card__icon}>{icon}</div>
        <div className={s.card__title}>{title}</div>
      </div>
      <div className={s.card__text}>{text}</div>
    </div>
  );
};

export default RaffleAboutCard;
