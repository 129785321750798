export const addQueryParam = (url: string, params?: Record<string, string | number | boolean>) => {
  const urlObj = new URL(url, window.location.origin);

  if (!params) return urlObj.toString();

  Object.entries(params).forEach(([key, value]) => {
    urlObj.searchParams.set(key, String(value));
  });

  return urlObj.toString();
};

export const addQueryParams = (
  url: string,
  params: Record<string, string | number | undefined>,
): string => {
  const urlParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined && value !== '') {
      urlParams.append(key, value.toString());
    }
  }

  return url + (urlParams.toString() ? `?${urlParams.toString()}` : '');
};

export const createImageUrl = (url: string) => {
  return `${process.env.REACT_APP_API_URL}${url}`;
};

export const createPublicImageUrl = (id: string) => {
  return `${process.env.REACT_APP_API_URL}/public/images/${id}.png`;
};
