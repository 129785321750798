import React from 'react';
import { ReactComponent as WinnerMedalIcon } from 'src/assets/icons/winnerMedal.svg';
import s from './style.module.scss';
import { IRafflePublicWinner } from 'src/@types/raffles';

interface IWinnerCardProps {
  item: IRafflePublicWinner;
}

const WinnerCard = ({ item }: IWinnerCardProps) => {
  return (
    <div className={s.card}>
      <WinnerMedalIcon />
      <div className={s.card__avatar}>
        <img src={item.avatarUrl} alt='avatar' />
      </div>
      <div title={item.accountName} className={s.card__name}>
        {item.accountName}
      </div>
    </div>
  );
};

export default WinnerCard;
