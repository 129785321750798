import React from 'react';
import s from './style.module.scss';

import Button from 'src/ui/Button';

// import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import CoinImage from '../../../assets/images/goldcoin.png';
import RaffleEndCounter from '../../EndCounter';
import RaffleParticipants from '../RaffleParticipants';
import useModal from 'src/hooks/useModal';
import useAuthStore from 'src/store/authStore';
import { createImageUrl, createPublicImageUrl } from 'src/utils/url';
import { IRaffle, RaffleStatus } from 'src/@types/raffles';

interface IRaffleCardProps {
  item: IRaffle;
  onClick?: () => void;
}

const apiUrl = process.env.REACT_APP_API_URL;

const RaffleCard = ({ item, onClick }: IRaffleCardProps) => {
  const { user, userSettings } = useAuthStore();
  const isUserLoggedIn = user !== null;
  const { isOpen, openModal, closeModal } = useModal();

  const handleJoinClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // if (!isUserLoggedIn) {
    //   // show login modal
    //   openModal();
    // } else {
    if (onClick) {
      e.stopPropagation();
      onClick();
      // }
    }
  };

  return (
    <>
      <div className={s.card}>
        <div className={s.card__bg} />

        <div className={s.card__content}>
          <div className={s.card__head}>
            <div className={s.card__image}>
              <img src={item.imageId ? createPublicImageUrl(item.imageId) : CoinImage} alt='coin' />
            </div>
          </div>

          <div className={s.card__content}>
            <div className={s.card__title}>{item.name}</div>
            <RaffleParticipants
              winners={item.numberOfWinners}
              maxParticipants={item.maxPlayers}
              maxWinners={item.maxWinners}
              participants={item.numberOfPlayers}
            />

            <RaffleEndCounter endDate={item.endDate} />
            {item.status === RaffleStatus.COMPLETED ||
            item.status === RaffleStatus.UPCOMING ||
            item.joined ? (
              <Button onClick={handleJoinClick} isOutlined>
                Review
              </Button>
            ) : (
              <Button onClick={handleJoinClick}>Join</Button>
            )}
          </div>
        </div>
      </div>

      {/* <Modal maxWidth='sm' isCloseIcon open={isOpen} handleClose={closeModal}>
        <LoginWarning />
      </Modal> */}
    </>
  );
};

export default RaffleCard;
