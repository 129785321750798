import React from 'react';

import s from './style.module.scss';

import Hero from 'src/components/giveaways/Hero';
import GiveawaysList from 'src/components/giveaways/GiveawaysList';

const Giveaways: React.FC = () => {
  return (
    <div className={s.raffles}>
      <Hero />

      <div className={s.raffles__container}>
        <div className='container'>
          <div className={s.raffles__content}>
            <GiveawaysList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Giveaways;
