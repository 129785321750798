import React from 'react';
import { createBrowserRouter, Outlet } from 'react-router';
//@ts-ignore
import { RouterProvider } from 'react-router/dom';
import { routes } from 'src/routes';
import { PlayerProfile } from 'src/pages/PlayerProfile';
import Challenges from 'src/pages/Challenges';
import Highlights from 'src/pages/Highlights';
import Leaderboard from 'src/pages/Leaderboard';
import { Store } from 'src/pages/Store';
import { SlotBattles } from 'src/pages/SlotBattles';
import Cases from 'src/pages/Cases';
import Hunts from 'src/pages/Hunts';
import Hunt from 'src/pages/Hunt';
import Raffles from 'src/pages/Raffles';
import Giveaway from 'src/pages/Giveaway';
import Giveaways from 'src/pages/Giveaways';
import AdminLayout from 'src/layouts/AdminLayout';
import RafflesAdmin from 'src/pages/Admin/RafflesAdmin';
import ChallengesAdmin from 'src/pages/Admin/ChallengesAdmin';
import HighlightsAdmin from 'src/pages/Admin/HighlightsAdmin';
import ItemsAdmin from 'src/pages/Admin/ItemsAdmin';
import StoreAdmin from 'src/pages/Admin/StoreAdmin';
import StoreLogs from 'src/pages/Admin/StoreLogs';
import SlotBattlesAdmin from 'src/pages/Admin/SlotBattlesAdmin';
import PlayersAdmin from 'src/pages/Admin/PlayersAdmin';
import PlayerViewAdmin from 'src/pages/Admin/PlayerViewAdmin';
import LeaderboardAdmin from 'src/pages/Admin/LeaderboardAdmin';
import DashbordAdmin from 'src/pages/Admin/DashboardAdmin';
import RaffleAdmin from 'src/pages/Admin/RaffleAdmin';
import { Landing } from 'src/pages/Landing';
import { VIP } from 'src/pages/VIP';
import Casinos from 'src/pages/Casinos';
import TermsOfService from 'src/pages/TermsOfService';
import { PlayerSettings } from 'src/pages/PlayerSettings';
import FAQ from 'src/pages/FAQ';
import PrivacyPolicy from 'src/pages/PrivacyPolicy';
import Raffle from 'src/pages/Raffle';
import { Header } from 'src/components/Header';
import AppLayout from 'src/layouts/AppLayout';

const router = createBrowserRouter([
  {
    path: routes.home,
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Landing />,
      },
      {
        path: routes.vip,
        element: <VIP />,
      },
      {
        path: '/admin',
        element: <AdminLayout />,
        children: [
          { path: 'dashboard', element: <DashbordAdmin /> },
          { path: 'raffles', element: <RafflesAdmin /> },
          { path: 'raffles/:id', element: <RaffleAdmin /> },
          { path: 'challenges', element: <ChallengesAdmin /> },
          { path: 'highlights', element: <HighlightsAdmin /> },
          { path: 'items', element: <ItemsAdmin /> },
          { path: 'store', element: <StoreAdmin /> },
          { path: 'store-log', element: <StoreLogs /> },
          { path: 'battles', element: <SlotBattlesAdmin /> },
          { path: 'users', element: <PlayersAdmin /> },
          { path: 'users/:id', element: <PlayerViewAdmin /> },
          { path: 'leaderboard', element: <LeaderboardAdmin /> },
        ],
      },
      {
        path: routes.casino,
        element: <Casinos />,
      },
      {
        path: routes.leaderboard,
        element: <Leaderboard />,
      },
      {
        path: routes.highlights,
        element: <Highlights />,
      },
      {
        path: routes.challenges,
        element: <Challenges />,
      },
      {
        path: routes.giveaways,
        element: <Giveaways />,
      },
      {
        path: routes.giveaway,
        element: <Giveaway />,
      },
      {
        path: routes.raffles,
        element: <Raffles />,
      },
      {
        path: routes.raffle,
        element: <Raffle />,
      },
      {
        path: routes.cases,
        element: <Cases />,
      },
      {
        path: routes.battles,
        element: <SlotBattles />,
      },
      {
        path: routes.hunts,
        element: <Hunts />,
      },
      {
        path: routes.hunt,
        element: <Hunt />,
      },
      {
        path: routes.store,
        element: <Store />,
      },
      {
        path: routes.profile,
        element: <PlayerProfile />,
      },
      {
        path: routes.settings,
        element: <PlayerSettings />,
      },
      {
        path: routes.tos,
        element: <TermsOfService />,
      },
      {
        path: routes.faq,
        element: <FAQ />,
      },
      {
        path: routes.privacyPolicy,
        element: <PrivacyPolicy />,
      },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
