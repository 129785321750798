import React, { useEffect, useState } from 'react';
import '../assets/CSS/Bonushunts.scss';
import '../assets/CSS/Bonushunts.responsive.scss';
import { BonusHunt, BonusHuntOverview } from '../types';
import { axiosGet } from '../utils/httpClient';
import { useParams } from 'react-router';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
// import {makeStyles} from "@material-ui/core/styles";
import { makeStyles } from '@mui/styles';
import { formatDateYearTime } from 'src/components/Crates';
import { numberWithCommas } from '../utils/utils';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      // width: "25ch"
    },
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  card: {
    height: '100%',
    backgroundColor: 'transparent !important',
    margin: '25px 10px 0 0',
    paddingTop: '15px',
    boxShadow: 'none',
    border: '2px solid #828fb9',
    position: 'relative',
  },
  cardContent: {
    background: 'linear-gradient(180deg, rgba(37,41,55,1) 0%, rgba(0,0,0,.1) 100%)',
    height: '80px',
    marginTop: '-20px',
    padding: '15px 15px 15px 15px !important',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 8px',
    color: 'white',
    textAlign: 'center',
  },
  table: {
    border: '3px solid #7a87b4 !important',
    borderRadius: '10px',
  },
  tableHeader: {
    background: '#121418 !important',
    // border: '1px solid #2a2e38 !important',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      background: '#222635 !important',
    },
    '&:nth-of-type(even)': {
      background: '#222635 !important',
    },
    border: '1px solid #2a2e38 !important',
    whiteSpace: 'nowrap',
  },
  headerCell: {
    background: '#101115 !important',
    color: 'rgb(223, 218, 228) !important',
    borderBottom: '1px solid #2a2e38 !important',
    '&:first-child': {
      borderTopLeftRadius: '10px',
    },
  },
  tableCell: {
    color: 'rgb(223, 218, 228) !important',
    borderBottom: '1px solid #2a2e38 !important',
    cursor: 'pointer',
  },
}));

interface Column {
  id: 'sequenceOrder' | 'game' | 'bet' | 'multiplier' | 'win';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any) => string;
}

const columns: readonly Column[] = [
  { id: 'sequenceOrder', label: '#', minWidth: 100, format: (v: any) => v ?? 'N/A' },
  { id: 'game', label: 'Slot', minWidth: 100, format: (v: any) => v ?? 'N/A' },
  { id: 'bet', label: 'Bet', minWidth: 100, format: (v: any) => v ?? 'N/A' },
  {
    id: 'multiplier',
    label: 'Multiplier',
    minWidth: 100,
    format: (v: number) => (v ? v.toFixed(2) + 'x' : 'N/A'),
  },
  { id: 'win', label: 'Win', minWidth: 100, format: (v: any) => v ?? 'N/A' },
];

const getHuntsOverview = async (): Promise<BonusHuntOverview> => axiosGet(`/general/bonus-hunts`);

export default function Hunt() {
  const classes = useStyles();
  const [tab, setTab] = useState<number>(0);
  const [data, setData] = useState<BonusHuntOverview | undefined>(undefined);

  const params = useParams();
  const huntId = params.hunt;
  let hunt: BonusHunt | undefined = undefined;
  if (huntId && data) {
    hunt = data.hunts.find((h) => h.id == huntId);
  }

  useEffect(() => {
    getHuntsOverview().then((response) => {
      setData(response);
      response.hunts.forEach((hunt, index) => {
        hunt.order = `${response.hunts.length - index}`;
      });
    });
  }, []);

  if (hunt) {
    const stats: Array<any> = [
      { stat: 'Streamer', value: hunt.streamer, icon: '/...' },
      { stat: 'Date', value: formatDateYearTime(hunt.creationDate) },
      {
        stat: 'Start',
        value: hunt.start ? hunt.currency + '' + numberWithCommas(hunt!.start!) : '-',
      },
      {
        stat: 'Highest Win',
        value: hunt.highestWin ? '$' + numberWithCommas(hunt.highestWin) : '-',
      },
      { stat: 'Profit', value: hunt.profit ? '$' + numberWithCommas(hunt.profit) : '-' },
      { stat: 'Req. Avg. x', value: hunt.reqAvg },
      { stat: 'Run. Avg. X', value: hunt.runAvg },
      {
        stat: 'Highest Multiplier',
        value: hunt.highestMultiplier ? 'x' + hunt.highestMultiplier.toLocaleString('en-US') : '-',
      },
      {
        stat: 'Total Bonuses',
        value: hunt.totalBonuses ? hunt.totalBonuses.toLocaleString('en-US') : '-',
      },
      {
        stat: 'Opened Bonuses',
        value: hunt.openedBonuses ? hunt.openedBonuses.toLocaleString('en-US') : '-',
      },
    ];

    const rows = hunt.slots ?? [];
    return (
      <>
        <div className='App-contents Challenges VIP'>
          <div className='Landing-content'>
            <br />
            <br />
            <div className='Landing-heading'>
              <h2 className='Main-heading'>{hunt.id}</h2>
              <div className='Heading-divider' />
              {/*<p className="Main-subheading">Step into the spotlight with our leaderboard section! See who's*/}
              {/*    crushing it and<br/> join the fun as you aim for the top spot among fellow contenders.</p>*/}
            </div>
            <br />
            <br />
            <br />
            <br />
            <div className='Bonus-hunts-container'>
              <div className='Bonus-hunts-content-container'>
                <div className='App-contents Challenges' key='unselected'>
                  <div className='Bonus-hunt-overview'>
                    <div className='Active-filter'>
                      <div className={tab == 0 ? `Active` : ''} onClick={() => setTab(0)}>
                        Stats
                      </div>
                      <div className={tab == 1 ? `Active` : ''} onClick={() => setTab(1)}>
                        Guesses
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className='overview-stats'>
                      <p className='Stat-header'>Bonus Hunt #{hunt.order}</p>
                      <Grid className={classes.gridList} spacing={0} container>
                        {stats.map((tile) => (
                          <Card
                            sx={{ minWidth: 265 }}
                            className={
                              classes.card +
                              ' mobile-content ' +
                              (tile.stat == 'Profit' ? 'GreenedCard' : '')
                            }>
                            <CardContent className={classes.cardContent + ' mobile-content'}>
                              <p className='Heading'>{tile.stat}</p>
                              <Typography variant='body2'>
                                <Typography
                                  className='Value'
                                  gutterBottom
                                  variant='h5'
                                  component='div'>
                                  {tile.value}
                                </Typography>
                              </Typography>
                            </CardContent>
                          </Card>
                        ))}
                      </Grid>
                      <div className='Bonus-hunts-container'>
                        <div className='Bonus-hunts-guessing-state'>
                          <h4>Guessing Closed</h4>
                        </div>
                      </div>
                      {tab == 0 ? (
                        <></>
                      ) : (
                        <div className='Guess-the-balance'>
                          <h3>Guess The Balance</h3>

                          <span>Win points by placing your prediction of the end balance!</span>

                          <div className='Button-set'>
                            <button>
                              <span>10%</span>
                              <span>No Bet</span>
                            </button>
                            <button>
                              <span>5%</span>
                              <span>No Bet</span>
                            </button>
                            <button>
                              <span>1%</span>
                              <span>No Bet</span>
                            </button>
                            <button>
                              <span>Exact</span>
                              <span>No Bet</span>
                            </button>
                          </div>

                          <span>Your guess needs to be the same as the hunt end balance.</span>
                          <span>
                            You can read more about payouts <span className='No-padding'>here</span>
                            .
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  {/*<div className="Leaderboard-Pedestal-container">*/}
                  {/* <div className="Leaderboard-Pedestal"> */}
                  {/*        <div className="Rankings">*/}
                  {/*            <div className="Ranking">*/}
                  {/*                <div className="Content Silver">*/}
                  {/*                    <div className="User-icon">*/}
                  {/*                        <img src={'/logo.gif'}/>*/}
                  {/*                    </div>*/}
                  {/*                    <div className="User-info">*/}
                  {/*                        <h4>2nd</h4>*/}
                  {/*                        <br />*/}
                  {/*                        <span>guess</span>*/}
                  {/*                        <h4>$0.00</h4>*/}
                  {/*                        <div className="leaderboard-username">*/}
                  {/*                            <span className="Username">Jac</span>*/}
                  {/*                        </div>*/}
                  {/*                    </div>*/}
                  {/*                </div>*/}
                  {/*            </div>*/}
                  {/*            <div className="Ranking">*/}
                  {/*                <div className="Content Gold">*/}
                  {/*                    <div className="User-icon">*/}
                  {/*                        <img src={'/logo.gif'}/>*/}
                  {/*                    </div>*/}
                  {/*                    <div className="User-info">*/}
                  {/*                        <h4>1st</h4>*/}
                  {/*                        <br />*/}
                  {/*                        <span>guess</span>*/}
                  {/*                        <h4>$0.00</h4>*/}
                  {/*                        <div className="leaderboard-username">*/}
                  {/*                            <span className="Username">Jac</span>*/}
                  {/*                        </div>*/}
                  {/*                    </div>*/}
                  {/*                </div>*/}
                  {/*            </div>*/}
                  {/*            <div className="Ranking">*/}
                  {/*                <div className="Content Bronze">*/}
                  {/*                    <div className="User-icon">*/}
                  {/*                        <img src={'/logo.gif'}/>*/}
                  {/*                    </div>*/}
                  {/*                    <div className="User-info">*/}
                  {/*                        <h4>3rd</h4>*/}
                  {/*                        <br />*/}
                  {/*                        <span>guess</span>*/}
                  {/*                        <h4>$0.00</h4>*/}
                  {/*                        <div className="leaderboard-username">*/}
                  {/*                            <span className="Username">Jac</span>*/}
                  {/*                        </div>*/}
                  {/*                    </div>*/}
                  {/*                </div>*/}
                  {/*            </div>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  <div className='Bonus-hunt-search'>
                    <div className='search-input'>
                      <img src='/navigation/search.svg' width={20} />
                      <input type='text' placeholder='Search' />
                    </div>
                  </div>
                  <div className='Bonus-hunt-slots'>
                    <Paper sx={{ width: '100%', overflow: 'hidden', background: 'transparent' }}>
                      <TableContainer sx={{ maxHeight: 840 }}>
                        <Table className={classes.table} stickyHeader aria-label='sticky table'>
                          <TableHead className={classes.tableHeader}>
                            <TableRow className={classes.tableRow}>
                              {columns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  className={classes.headerCell}
                                  style={{ minWidth: column.minWidth }}>
                                  <span>{column.label}</span>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => {
                              return (
                                <TableRow
                                  className={classes.tableRow}
                                  hover
                                  role='checkbox'
                                  tabIndex={-1}
                                  key={row.sequenceOrder}>
                                  {columns.map((column) => {
                                    // @ts-ignore
                                    const value: any = row[column.id];

                                    return (
                                      <TableCell
                                        className={classes.tableCell}
                                        key={column.id}
                                        align={column.align}>
                                        <span>
                                          {
                                            // @ts-ignore
                                            column.format(value)
                                          }
                                        </span>
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className='App-contents Challenges VIP'>
        <div className='Landing-content'>
          <br />
          <br />
          <div className='Landing-heading'>
            <h2 className='Main-heading'>Loading data...</h2>
            <div className='Heading-divider' />
          </div>
        </div>
      </div>
    </>
  );
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <Typography
          variant='body2'
          style={{ textAlign: 'right', color: 'white' }}
          color='text.secondary'>{`${Math.round(props.value)}%`}</Typography>
        <LinearProgress variant='determinate' {...props} />
      </Box>
    </Box>
  );
}

function LinearWithValueLabel(progress: number) {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
