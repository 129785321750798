import s from './style.module.scss';
import moderateRafflesService from 'src/services/moderateRafflesService';
import Pagination from 'src/ui/Pagination';
import EntryItem from '../EntryItem';
import { Tab, TabList, TabPanel, Tabs } from 'src/ui/Tabs';
import { useParams, useSearchParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { REFETCHING_TIME } from 'src/constants/fetching';
import Input from 'src/ui/Input';
import { useDebounce } from 'use-debounce';
import { IRafflePlayer, RewardStatus } from 'src/@types/raffles';

const RaffleParticipantsSection = ({ itemsPerPage, playersPage, handleChangePage, onBan }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const [bannedPlayers, setBannedPlayers] = useState<IRafflePlayer[]>([]);
  const [winnersPlayers, setWinnersPlayers] = useState<IRafflePlayer[]>([]);

  const [search, setSearch] = useState('');
  const [searchUserName] = useDebounce(search, 1000);

  const tab = Number(searchParams.get('tab'));

  const {
    data: playersData,
    isLoading: isLoadingPlayers,
    refetch: refetchPlayers,
  } = useQuery({
    queryKey: ['players', id],
    queryFn: () => {
      const formQuery = searchUserName
        ? { id: id ? id : '', name: searchUserName }
        : {
            id: id ? id : '',
            page: playersPage === 0 ? playersPage : playersPage - 1,
            size: itemsPerPage,
          };

      return moderateRafflesService.getPlayersForRaffle(formQuery);
    },
    refetchInterval: REFETCHING_TIME,
  });

  const {
    data: winnersData,
    isLoading: isLoadingWinners,
    refetch: refetchWinners,
  } = useQuery({
    queryKey: ['winners', id],
    queryFn: () => {
      const formQuery = searchUserName
        ? { id: id ? id : '', name: searchUserName }
        : {
            id: id ? id : '',
            page: playersPage === 0 ? playersPage : playersPage - 1,
            size: itemsPerPage,
          };

      return moderateRafflesService.getWinnersForRaffle(formQuery);
    },
    refetchInterval: REFETCHING_TIME,
  });

  const {
    data: bannedData,
    isLoading: isLoadingBanned,
    refetch: refetchBanned,
  } = useQuery({
    queryKey: ['banned', id],
    queryFn: () =>
      moderateRafflesService.getPlayersForRaffle({
        id: id ? id : '',
        name: searchUserName,
      }),
    refetchInterval: REFETCHING_TIME,
  });

  const { mutate: getReward } = useMutation({
    mutationKey: ['getReward', id],
    mutationFn: (variables: any) => moderateRafflesService.getReward(variables),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['winners', id] });
      toast.success('Winner rewarded');
    },
    onError: (error: any) => {
      const errorMessage = error?.response?.data?.message || 'Error reward winner';
      toast.error(errorMessage);
      console.error('Winner reward error:', error);
    },
  });

  const renderHeader = () => {
    if (tab === 0) {
      return (
        <>
          Participants <span>({playersData?.total})</span>
        </>
      );
    } else if (tab === 1) {
      return (
        <>
          Winners <span>({winnersData?.total})</span>
        </>
      );
    }
    return (
      <>
        Banned <span>({bannedPlayers.length})</span>
      </>
    );
  };

  const onGiveReward = (accountId: string) => {
    //FIX: update user accout when reward (if it is your own raffle)
    getReward({ raffleId: id, accountId });
  };

  const refetchDataAfterTabChange = (tab: number) => {
    if (tab === 0) {
      refetchPlayers();
    } else if (tab === 1) {
      refetchWinners();
    } else if (tab === 2) {
      refetchBanned();
    }
  };

  const handleChangeTab = (tab: number) => {
    refetchDataAfterTabChange(tab);

    setSearchParams((prev) => {
      prev.set('tab', tab.toString());
      return prev;
    });
  };

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('tab', '0');
      return prev;
    });
  }, []);

  useEffect(() => {
    if (!searchUserName) {
      setSearchParams((prev) => {
        prev.delete('searchName');
        return prev;
      });

      refetchDataAfterTabChange(tab);

      return;
    }

    setSearchParams((prev) => {
      prev.set('searchName', searchUserName);
      return prev;
    });

    refetchDataAfterTabChange(tab);
  }, [searchUserName]);

  useEffect(() => {
    if (bannedData) {
      const banned = bannedData.data.filter((item: IRafflePlayer) => item.banned);
      setBannedPlayers(banned);
    }
  }, [bannedData]);

  useEffect(() => {
    if (winnersData) {
      const winners = winnersData.data.filter((item: IRafflePlayer) => item.banned !== true);
      setWinnersPlayers(winners);
    }
  }, [winnersData]);

  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('playersPage', '1');
      prev.delete('searchName');
      return prev;
    });

    setSearch('');
  }, [tab]);

  useEffect(() => {
    refetchDataAfterTabChange(tab);
  }, [playersPage]);

  const bannedPlayersPage = Number(searchParams.get('playersPage')) || 1;
  const startIndex = (bannedPlayersPage - 1) * itemsPerPage;
  const endIndex = bannedPlayersPage * itemsPerPage;
  const paginatedBannedPlayers = bannedPlayers.slice(startIndex, endIndex);

  if (isLoadingPlayers || isLoadingWinners || isLoadingBanned) {
    return <div>Loading...</div>;
  }

  return (
    <div className={s.entries}>
      <div className={s.entries__head}>
        <h4 className={s.entries__title}>{renderHeader()}</h4>

        <div>
          {
            <Input
              placeholder='Search'
              className={s.raffle__search}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          }
        </div>
      </div>

      <Tabs selectedIndex={tab} onSelect={(index) => handleChangeTab(index)}>
        <div style={{ marginBottom: '50px' }}>
          <TabList className={s.tabs__tabList}>
            <Tab>Participants</Tab>
            <Tab>Winners</Tab>
            <Tab>Banned</Tab>
          </TabList>
        </div>
        <TabPanel>
          {playersData?.total === 0 ? (
            <div className={s.raffle__empty}>
              <h4>No Participants Yet</h4>
            </div>
          ) : (
            <div className={s.entries__content}>
              <ul className={s.entries__list}>
                {playersData?.data.map((item, index) => {
                  return (
                    <li key={index}>
                      <EntryItem onBan={() => onBan(item)} item={item} />
                    </li>
                  );
                })}
              </ul>

              {playersData && playersData.total > itemsPerPage && (
                <Pagination
                  itemsPerPage={itemsPerPage}
                  count={Math.ceil(playersData?.total / itemsPerPage)}
                  page={playersPage}
                  onChangePage={(e, value) => handleChangePage(value)}
                />
              )}
            </div>
          )}
        </TabPanel>

        <TabPanel>
          {winnersData?.total === 0 ? (
            <div className={s.raffle__empty}>
              <h4>No Winners Yet</h4>
            </div>
          ) : (
            <div className={s.entries__content}>
              <ul className={s.entries__list}>
                {winnersPlayers.map((item, index) => {
                  const isRewarded = item?.rewardStatus === RewardStatus.REWARDED;

                  return (
                    <li key={index}>
                      <EntryItem
                        isWinner={true}
                        isRewarded={isRewarded}
                        onReward={!isRewarded ? onGiveReward : undefined}
                        onBan={() => onBan(item)}
                        item={item}
                      />
                    </li>
                  );
                })}
              </ul>

              {winnersData && winnersData.total > itemsPerPage && (
                <Pagination
                  itemsPerPage={itemsPerPage}
                  count={Math.ceil(winnersData?.total / itemsPerPage)}
                  page={playersPage}
                  onChangePage={(e, value) => handleChangePage(value)}
                />
              )}
            </div>
          )}
        </TabPanel>

        <TabPanel>
          {bannedPlayers.length === 0 ? (
            <div className={s.raffle__empty}>
              <h4>No Banned Yet</h4>
            </div>
          ) : (
            <div className={s.entries__content}>
              <ul className={s.entries__list}>
                {paginatedBannedPlayers.map((item, index) => {
                  return (
                    <li key={index}>
                      <EntryItem onBan={() => onBan(item)} item={item} />
                    </li>
                  );
                })}
              </ul>

              {bannedPlayers.length > itemsPerPage && (
                <Pagination
                  itemsPerPage={itemsPerPage}
                  count={Math.ceil(bannedPlayers.length / itemsPerPage)}
                  page={bannedPlayersPage}
                  onChangePage={(e, value) => {
                    setSearchParams((prev) => {
                      prev.set('playersPage', value.toString());
                      return prev;
                    });
                  }}
                />
              )}
            </div>
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default RaffleParticipantsSection;
