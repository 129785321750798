import React from 'react';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';
import s from './style.module.scss';
import Button from '../Button';

interface IFileUploaderProps {
  value: File | string | null;
  setValue: (value: File | null) => void;
  url?: any;
  onDelete?: () => void;
  title?: string;
  label?: string;
  name?: string;
  acceptedFileTypes?: string[];
}

const FileUploader = ({
  value,
  url,
  name,
  setValue,
  onDelete,
  label = 'Your file',
  title = 'Attach your file here',
  acceptedFileTypes = [],
}: IFileUploaderProps) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    if (selectedFile) {
      if (acceptedFileTypes.length && !acceptedFileTypes.includes(selectedFile.type)) {
        alert('Invalid file type. Please upload a valid file.');
        return;
      }
      setValue(selectedFile);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files ? event.dataTransfer.files[0] : null;
    if (droppedFile) {
      if (acceptedFileTypes.length && !acceptedFileTypes.includes(droppedFile.type)) {
        alert('Invalid file type. Please upload a valid file.');
        return;
      }
      setValue(droppedFile);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div className={s['file-uploader']}>
      {value ? (
        <div className={s['file-uploader__details']}>
          <div className={s['file-uploader__label']}>{label}</div>

          {url && (
            <div className={s['file-uploader__image']}>
              <img src={url} alt='photo' />
              <Button isIcon onClick={onDelete} className={s['file-uploader__delete']}>
                <TrashIcon className={s['file-uploader__trash-icon']} />
              </Button>
            </div>
          )}

          {/* <div className={s['file-uploader__info']}>
            <div className={s['file-uploader__file']}>
              <FileIcon className={s['file-uploader__icon']} />
              <div>{typeof value === 'string' ? name : value.name}</div>
            </div>

            <button onClick={onDelete} className={s['file-uploader__delete']}>
              Delete
              <TrashIcon className={s['file-uploader__trash-icon']} />
            </button>
          </div> */}
        </div>
      ) : (
        <div
          className={s['file-uploader__dropzone']}
          onDrop={handleDrop}
          onDragOver={handleDragOver}>
          <p className={s['file-uploader__title']}>{title}</p>
          <p className={s['file-uploader__subtitle']}>or just drag & drop</p>
          <input
            type='file'
            onChange={handleFileChange}
            className={s['file-uploader__input']}
            accept={acceptedFileTypes.join(',')}
          />
        </div>
      )}
    </div>
  );
};

export default FileUploader;
