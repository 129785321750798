import React from 'react';
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg';
import s from './style.module.scss';
import Button from 'src/ui/Button';
import Input from 'src/ui/Input';

const DynamicList = ({
  title,
  items,
  onAdd,
  onDelete,
  onChange,
  renderItemHeader = (index) => `Item #${index + 1}`,
  placeholder = 'Enter value...',
}) => {
  return (
    <div className={s.links}>
      <div className={s.links__title}>{title}</div>

      <div className={s.links__item}>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <div className={s.links__header}>
              <div className={s.links__subTitle}>{renderItemHeader(index)}</div>

              <Button
                isIcon
                color='red'
                borderRadius='6px'
                onClick={() => onDelete(index)}
                className='text-red-500'>
                <TrashIcon />
              </Button>
            </div>

            <div>
              <Input
                name={`item-${index}`}
                value={item}
                onChange={(e) => onChange(index, e.target.value)}
                placeholder={placeholder}
                className='!rounded-10'
              />
            </div>
          </React.Fragment>
        ))}
      </div>

      <div className={s.links__title}>
        <Button isClear onClick={onAdd}>
          + Add another item
        </Button>
      </div>
    </div>
  );
};

export default DynamicList;
