import React, { useEffect, useState } from 'react';
import '../assets/CSS/App.scss';

import { makeStyles } from '@mui/styles';
// import {makeStyles} from "@material-ui/core/styles";
import { Card, CardContent, CardMedia } from '@mui/material';
import { HighlightsApi } from '../types';
import { axiosGet } from '../utils/httpClient';

import D3Image1 from 'src/assets/images/d31.jpg';
import D3Image2 from 'src/assets/images/d32.jpg';
import D3Image3 from 'src/assets/images/d33.jpg';
import D3Image4 from 'src/assets/images/d34.jpg';
import D3Image5 from 'src/assets/images/d35.jpg';
import D3Image6 from 'src/assets/images/d36.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  gridList: {
    width: '100%',
    height: 'auto',
    display: 'inline-flex',
    flexDirection: 'row',
  },
  card: {
    height: '280px',
    maxWidth: '445px',
    minWidth: '445px',
    transition: '.6s !important',
    backgroundColor: 'transparent !important',
    padding: '0 !important',
    margin: '0 !important',
    boxShadow: 'none !important',
    border: 'none',
    borderRadius: '15px !important',
  },
  cardMedia: {
    height: '220px',
    borderRadius: '15px',
    boxShadow:
      '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 4px 4px 0px rgba(0, 0, 0, 0.14), 0px 4px 7px 0px rgba(0, 0, 0, 0.12) !important',
  },
  cardContent: {
    textAlign: 'left',
    fontSize: '11px !important',
    boxShadow: 'none',
    padding: '0 !important',
    color: '#cecece',
  },
}));

export type VideosProps = {};

const getHighlights = async (): Promise<HighlightsApi> => axiosGet(`/general/highlight-clips`);

export const StreamersVideos: React.FC<VideosProps> = ({}) => {
  const classes = useStyles();

  const [data, setData] = useState<HighlightsApi | undefined>(undefined);

  useEffect(() => {
    getHighlights().then((response) => {
      // let highlights = response;

      // let D3Avatar =
      //   'https://files.kick.com/images/user/2395240/profile_image/conversion/b72a11da-35bd-43aa-a0de-15789d9a3eeb-fullsize.webp';

      // let D3Videos = {
      //   streamer: 'D3',
      //   videos: [
      //     {
      //       title: 'I Started with $100 and Walked Away With This...',
      //       thumbnail: D3Image1,
      //       url: 'https://www.youtube.com/watch?v=E3ipy_V2X54',
      //       uploader: 'D3',
      //       uploaderImage: D3Avatar,
      //       game: 'D3',
      //       multiplier: 1,
      //     },
      //     {
      //       title: 'I Took My $1,000.00 Balance to Sugar Rush 1000 !',
      //       thumbnail: D3Image2,
      //       url: 'https://www.youtube.com/watch?v=SgMGu346RJA',
      //       uploader: 'D3',
      //       uploaderImage: D3Avatar,
      //       game: 'D3',
      //       multiplier: 1,
      //     },
      //     {
      //       title: 'Gambling In Toronto 🇨🇦',
      //       thumbnail: D3Image3,
      //       url: 'https://www.youtube.com/watch?v=o0rE38-_wos',
      //       uploader: 'D3',
      //       uploaderImage: D3Avatar,
      //       game: 'D3',
      //       multiplier: 1,
      //     },
      //     {
      //       title: 'I Doubled Up on Blackjack to Win Back My Losses',
      //       thumbnail: D3Image4,
      //       url: 'https://www.youtube.com/watch?v=jb8FlluFY9w',
      //       uploader: 'D3',
      //       uploaderImage: D3Avatar,
      //       game: 'D3',
      //       multiplier: 1,
      //     },
      //     {
      //       title: 'I Lost $100, So I Risked $1,000 to Win It Back',
      //       thumbnail: D3Image5,
      //       url: 'https://www.youtube.com/watch?v=E3ipy_V2X54',
      //       uploader: 'D3',
      //       uploaderImage: D3Avatar,
      //       game: 'D3',
      //       multiplier: 1,
      //     },
      //     {
      //       title: 'CHASING the 1000x MAX Multiplier on Sweet Bonanza 1000',
      //       thumbnail: D3Image6,
      //       url: 'https://www.youtube.com/watch?v=8HApn5xeog8',
      //       uploader: 'D3',
      //       uploaderImage: D3Avatar,
      //       game: 'D3',
      //       multiplier: 1,
      //     },
      //   ],
      // };

      // highlights.streamersFeaturedVideos.push(D3Videos as any);

      setData(response);
    });
  }, []);
  const ordered = ['BTCs', 'Lance', 'Hunterowner', 'GrayGray', 'd3_rs'];
  return (
    <>
      {data ? (
        ordered.map((key) => {
          const streamer = data!.streamersFeaturedVideos.find((tile) => tile.streamer == key)!;
          return (
            <>
              <p className='StreamerName'>{streamer.streamer}</p>
              <div className='Infinite-horizontal Streamer-videos'>
                <div className={classes.gridList + ' Infinite-horizontal'}>
                  {streamer.videos.map((tile) => {
                    return (
                      <a target='_action' href={tile.url} className={'Video-case CardHover-video'}>
                        <Card className={classes.card + ' Card'}>
                          <CardMedia
                            component='img'
                            alt={tile.title}
                            className={classes.cardMedia}
                            height='140'
                            image={tile.thumbnail}
                          />
                          <CardContent className={classes.cardContent}>
                            <div className='Video-Details'>
                              <div className='Video-Uploader'>
                                <img src={tile.uploaderImage} />
                              </div>
                              <div className='Video-Title'>
                                <span>{tile.title}</span>
                                <p>{tile.uploader}</p>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </a>
                    );
                  })}
                </div>
              </div>
            </>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};
