import {
  axiosDelete,
  axiosGet,
  axiosGetWithHeaders,
  axiosPost,
  axiosPut,
} from 'src/utils/httpClient';
import { IGetRafflesResponse } from './playerService';
import { addQueryParams } from 'src/utils/url';
import { IBanUserBody, IRaffle, RaffleStatus, RaffleType } from 'src/@types/raffles';

const moderateRafflesService = {
  async createRaffle(body: any) {
    const response = await axiosPost('/moderate/raffles', body);

    return response;
  },

  async editRaffle({ id, body }: { id: string; body: IRaffle }) {
    const response = await axiosPut(`/moderate/raffles/${id}`, body);

    return response;
  },

  async editRaffleStatus({ id, status }: { id: string; status: RaffleStatus }) {
    const response = await axiosPut(`/moderate/raffles/${id}/${status}`);

    return response;
  },

  async getRaffle({ id }: { id: string }): Promise<IRaffle> {
    const response = await axiosGet(`/moderate/raffles/${id}`);

    return response;
  },

  async getRaffles({
    type,
    status,
    page,
    size,
    sortColumn,
    sortOrder,
  }: {
    type: RaffleType;
    status?: RaffleStatus;
    page?: number;
    size?: number;
    sortColumn?: string | null;
    sortOrder?: string;
  }): Promise<IGetRafflesResponse> {
    let url = `/moderate/raffles?type=${type}&page=${page}&size=${size}`;

    if (sortColumn && sortOrder) {
      url += `&sort=${sortColumn},${sortOrder}`;
    }

    if (status) {
      url += `&status=${status}`;
    }

    const response = await axiosGetWithHeaders(url);

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10),
    };
  },

  async getGiveawaysRaffles({
    type,
    status,
  }: {
    type: RaffleType;
    status: RaffleStatus;
  }): Promise<IRaffle[]> {
    const response = await axiosGet(`/player/${type}/${status}`);

    return response;
  },

  async changeRaffleStatus({ id, status }: { id: string; status: RaffleStatus }) {
    const response = await axiosPut(`/moderate/raffles/${id}/${status}`);

    return response;
  },

  async deleteRaffle(id: string) {
    const response = await axiosDelete(`/moderate/raffles/${id}`);

    return response;
  },

  async createImage(file: FormData) {
    const response = await axiosPost('/moderate/images', file);

    return response;
  },

  async getReward({ raffleId, accountId }: { raffleId: string; accountId: string }) {
    const response = await axiosPost(`/moderate/raffle/${raffleId}/reward/${accountId}`);

    return response;
  },

  async banUser({ accountId, body }: { accountId: string; body: IBanUserBody }) {
    const response = await axiosPost(`/moderate/player/${accountId}/ban`, body);

    return response;
  },

  async unbanUser({ accountId, body }: { accountId: string; body: IBanUserBody }) {
    const response = await axiosPost(`/moderate/player/${accountId}/unban`, body);

    return response;
  },

  async getPlayersForRaffle({
    id,
    page,
    size,
    name,
  }: {
    id: string;
    page?: string | number;
    size?: string | number;
    name?: string;
  }): Promise<{ data: any; total: number }> {
    let url = `/moderate/raffles/${id}/players`;

    const formedUrl = addQueryParams(url, { page, size, name });

    const response = await axiosGetWithHeaders(formedUrl);

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10),
    };
  },

  async getWinnersForRaffle({
    id,
    page,
    size,
    name,
  }: {
    id: string;
    page?: string | number;
    size?: string | number;
    name?: string;
  }): Promise<{ data: any; total: number }> {
    let url = `/moderate/raffles/${id}/winners`;

    const formedUrl = addQueryParams(url, { page, size, name });

    const response = await axiosGetWithHeaders(formedUrl);

    return {
      data: response.data,
      total: parseInt(response.headers['x-total-count'], 10),
    };
  },

  async winnersSelection({ id }: { id: string }) {
    const response = await axiosPost(`/moderate/raffles/${id}/run/winners-selection`);

    return response;
  },
};

export default moderateRafflesService;
