import React from 'react';
import s from './style.module.scss';

import { ReactComponent as MedalIcon } from '../../../assets/icons/medal.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';

// import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';

interface IRaffleEndCounterProps {
  title?: string;
  winners: number | string;
  maxWinners?: number;
  participants: number | string;
  maxParticipants?: number;
}

const RaffleParticipants = ({
  title,
  winners,
  maxWinners,
  participants,
  maxParticipants,
}: IRaffleEndCounterProps) => {
  return (
    <div className={s.block}>
      {title && <div className={s.block__title}>{title}</div>}

      <div className={s.block__table}>
        <div className={s.block__iconContainer}>
          <MedalIcon />
          <div>
            <span className={s.block__value}>{winners}</span>/{maxWinners} Winners
          </div>
        </div>

        <div className={s.block__divider} />

        <div className={s.block__iconContainer}>
          <PeopleIcon />
          <div>
            <span className={s.block__value}>{participants}</span>/{maxParticipants}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaffleParticipants;
