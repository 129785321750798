import React from 'react';
import { TimeToEnd } from 'src/hooks/useTimeToEnd';

const EndCounter = ({ time }: { time: TimeToEnd | null }) => {
  return time ? (
    <p className='Main-subheading Leaderboard-subheading'>
      <span>{time?.days} days</span> {time?.hours}:{time?.minutes}:{time?.seconds}
    </p>
  ) : (
    <div className='Main-subheading Leaderboard-subheading'>Loading...</div>
  );
};

export default EndCounter;
