import {
  Dialog,
  FormControl,
  InputLabel,
  ListItemText,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { CreateHighlightRequest, EditHighlightRequest, HighlightClip } from 'src/types';
import { axiosPost, axiosPut } from 'src/utils/httpClient';
import MenuItem from '@mui/material/MenuItem';
import { ModalProps } from 'src/@types/modal';
import { dialogStyles, MenuProps, useStyles } from 'src/layouts/AdminLayout';

export type HighlightModalProps = {
  editing?: HighlightClip;
  setItems: any;
} & ModalProps;

function AddHighlightModal(props: HighlightModalProps) {
  const { onClose, open, setItems } = props;
  const classes = useStyles();

  const [title, setTitle] = useState<string | undefined>(undefined);
  const [thumbnail, setThumbnail] = useState<string | undefined>(undefined);
  const [game, setGame] = useState<string | undefined>(undefined);
  const [multiplier, setMultiplier] = useState<number>(0.0);
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [submittingHighlight, setSubmittingHighlight] = useState<boolean>(false);
  const [type, setType] = useState<number>(0);

  const addHighlight = async (request: CreateHighlightRequest): Promise<HighlightClip> =>
    axiosPost(`/moderate/create-highlight`, request);
  const editHighlight = async (request: EditHighlightRequest): Promise<Array<HighlightClip>> =>
    axiosPut(`/moderate/edit-highlight`, request);

  const handleClose = () => {
    onClose();
  };

  const handleTypeChange = (event: any) => {
    setType(Number(event.target.value));
  };

  const clipTypes = [
    { id: 0, text: 'GENERAL' },
    { id: 1, text: 'WIN_HIGHLIGHT' },
    { id: 2, text: 'TOP_VIEWER_BUYS' },
    { id: 3, text: 'ALL_TIME' },
  ];

  useEffect(() => {
    if (props.editing) {
      setTitle(props.editing.title);
      setThumbnail(props.editing.thumbnail);
      setUrl(props.editing.url);
      setType(clipTypes.find((t) => t.text == props.editing!.type)?.id ?? 0);
      setGame(props.editing.game);
      setMultiplier(props.editing.multiplier);
    }
  }, []);
  const types = ['GENERAL', 'WIN_HIGHLIGHT', 'TOP_VIEWER_BUYS', 'ALL_TIME'];

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
      <h2>{props.editing ? 'Edit' : 'Create'} Highlight Clip</h2>
      <div className='Btcs-form'>
        <div className='Form-input-field'>
          <h6>Clip Title</h6>
          <div className='Form-input'>
            <input
              type='text'
              placeholder='Clip Name...'
              defaultValue={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        </div>
        <div className='Form-input-field'>
          <div className='Form-input ModalSelect'>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id='demo-multiple-checkbox-label' sx={{ color: 'white' }}>
                Type
              </InputLabel>
              <Select
                labelId='demo-multiple-checkbox-label'
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                    root: classes.root,
                  },
                }}
                id='demo-multiple-checkbox'
                value={types[type]}
                onChange={handleTypeChange}
                sx={{
                  color: 'white',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#2a2e38',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#2a2e38',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#2a2e38',
                  },
                  '.MuiSvgIcon-root ': {
                    fill: 'white !important',
                  },
                }}
                input={<OutlinedInput sx={{ color: 'white' }} label='Type' />}
                renderValue={(selected) => selected}
                MenuProps={MenuProps}>
                {clipTypes.map((variant) => (
                  //@ts-ignore
                  <MenuItem key={variant.id} value={variant.id}>
                    <ListItemText primary={variant.text} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className='Form-input-field'>
          <h6>Thumbnail URL</h6>
          <div className='Form-input'>
            <input
              type='text'
              placeholder='Clip Thumbnail...'
              defaultValue={thumbnail}
              onChange={(e) => {
                setThumbnail(e.target.value);
              }}
            />
          </div>
        </div>

        <div className='Form-input-field'>
          <h6>Shuffle Game</h6>
          <div className='Form-input'>
            <input
              type='text'
              placeholder='Game...'
              defaultValue={game}
              onChange={(e) => {
                setGame(e.target.value);
              }}
            />
          </div>
        </div>

        <div className='Form-input-field'>
          <h6>Shuffle Multiplier</h6>
          <div className='Form-input'>
            <input
              type='number'
              min={1.0}
              step={0.1}
              defaultValue={multiplier}
              onChange={(e) => {
                setMultiplier(Number(e.target.value));
              }}
            />
          </div>
        </div>
        <div className='Form-input-field'>
          <h6>Clip URL</h6>
          <div className='Form-input'>
            <input
              type='text'
              placeholder='https://youtube.com/...'
              defaultValue={url}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
            />
          </div>
        </div>
        <div className='Form-submission'>
          <button
            className='Alt'
            onClick={() => {
              if (submittingHighlight) {
                return;
              }
              if (!thumbnail || !title || !url || !type || !game || !multiplier) {
                return;
              }
              setSubmittingHighlight(true);
              if (props.editing) {
                editHighlight({
                  id: props.editing.id,
                  title: title,
                  thumbnail: thumbnail,
                  url: url,
                  type: types[type],
                  game: game,
                  multiplier: multiplier,
                }).then((response) => {
                  setSubmittingHighlight(false);
                  setTitle(undefined);
                  setThumbnail(undefined);
                  setUrl(undefined);
                  setItems(response);
                  onClose();
                });
              } else {
                addHighlight({
                  title: title,
                  thumbnail: thumbnail,
                  url: url,
                  type: types[type],
                  game: game,
                  multiplier: multiplier,
                }).then((response) => {
                  setSubmittingHighlight(false);
                  setTitle(undefined);
                  setThumbnail(undefined);
                  setUrl(undefined);
                  setItems(response);
                  onClose();
                });
              }
            }}>
            {submittingHighlight ? 'Submitting...' : (props.editing ? 'Save' : 'Add') + ' Video'}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default AddHighlightModal;
