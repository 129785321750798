import React, { useEffect, useState } from 'react';
import 'src/assets/CSS/Leaderboard.scss';
import { LeaderboardEntry } from '../../types';
import { axiosGet, axiosGetShuffle } from '../../utils/httpClient';
import { calculateVIPRewards, endDate, msToTime, numberWithCommas } from '../../utils/utils';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { MenuProps } from '../Hunts';
import { dialogStyles, ModalProps } from 'src/layouts/AdminLayout';
import Dialog from '@mui/material/Dialog';
import { WagerLB } from 'src/components/WagerLB';
import { PrevMonthWagerLB } from 'src/constants/PrevLeaderboard';

import { makeStyles } from '@mui/styles';
import Button from 'src/ui/Button';
import { Link } from 'react-router';
import { routes } from 'src/routes';
import { DEFAULT_TIME_ZONE } from 'src/constants/time';
import { DateTime } from 'luxon';
import useTimeToEnd, { TimeToEnd } from 'src/hooks/useTimeToEnd';

import s from './style.module.scss';
import EndCounter from 'src/components/Leaderboard/EndCounter';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      // margin: theme.spacing(1),
      width: '25ch',
    },
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  card: {
    maxWidth: 300,
    height: '500px',
    backgroundColor: 'transparent !important',
    margin: '25px',
    paddingTop: '15px',
    border: '1px solid #2a2e38',
    boxShadow: 'none',
  },
  cardMedia: {
    height: '280px',
    padding: '0',
    marginTop: '-20px',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  cardContent: {
    background: 'linear-gradient(180deg, #041128, #1e1826)',
    height: '200px',
    borderTop: '1px solid #2d007d',
    textAlign: 'left',
    fontSize: '11px !important',
    color: '#cecece',
  },
  select: {
    '&:before': {
      borderColor: 'white !important',
    },
    '&:after': {
      borderColor: 'white !important',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white !important',
    },
    color: 'white !important',
    background: '#2c303e !important',
  },
  icon: {
    fill: 'white !important',
  },
  accordion: {
    background: 'linear-gradient(180deg, #041128, #1e1826) !important',
    borderRadius: '10px',
    color: 'white !important',
  },
}));

function RulesModal(props: ModalProps) {
  const classes = useStyles();
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
      <h2>Leaderboard Rules</h2>
      <br />
      <div className='rules'>
        <p>
          Playing under any of our codes contributes to the leaderboard & VIP rewards (Lance, D3,
          Gray, Hunter, BTCs).
        </p>
        <br />
        <p>Wager consisting of primarily house game play will be adjusted by 25-50%.</p>
      </div>
    </Dialog>
  );
}

const getLeaderboardResetDate = async (): Promise<number> => axiosGet(`/general/leaderboard-reset`);
const getTop3 = async (): Promise<Array<LeaderboardEntry>> =>
  axiosGet(`/general/top-3-leaderboard?limit=3`);
const getLeaderboard = async (): Promise<Array<LeaderboardEntry>> =>
  axiosGet(`/general/leaderboard`);

const getPreviousLeaderboard = async (): Promise<Array<LeaderboardEntry>> =>
  axiosGet(`/general/last-leaderboard`);

export default function Leaderboard() {
  const nextMonth = DateTime.now().setZone(DEFAULT_TIME_ZONE).endOf('month');
  const timeToNextMonth = useTimeToEnd(nextMonth);

  const LeaderboardFilterBar = (
    snapshotFilter: string,
    setSnapshotFilter: (filter: any) => any,
  ) => {
    const classes = useStyles();

    const handleSnapshotChange = (event: any) => {
      const {
        target: { value },
      } = event;
      setSnapshotFilter(value);
    };

    const snapshots = ['Current', 'Last Month'];
    return (
      <div className='Leaderboard-filter'>
        <div className='Board-filter'>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id='demo-multiple-checkbox-label' sx={{ color: 'white' }}>
              View
            </InputLabel>
            <Select
              labelId='demo-multiple-checkbox-label'
              className={classes.select}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  root: classes.root,
                },
              }}
              id='demo-multiple-checkbox'
              value={snapshotFilter}
              onChange={handleSnapshotChange}
              sx={{
                color: 'white',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#2a2e38',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#2a2e38',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#2a2e38',
                },
                '.MuiSvgIcon-root ': {
                  fill: 'white !important',
                },
              }}
              input={<OutlinedInput sx={{ color: 'white' }} label='View' />}
              renderValue={(selected) => selected}
              MenuProps={MenuProps}>
              {snapshots.map((variant) => (
                //@ts-ignore
                <MenuItem key={variant.id} value={variant}>
                  <ListItemText primary={variant} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    );
  };

  const [data, setData] = useState<Array<LeaderboardEntry>>([]);
  const [tab, setTab] = useState<number>(0);
  const [snapshotFilter, setSnapshotFilter] = useState<string>('Current');
  const [time, setTime] = useState<number>(0);
  const [top3, setTop3] = useState<Array<LeaderboardEntry>>([]);
  const [highlightFilter, setHighlightsFilter] = useState<string>('1st Place');
  const [open, setRulesOpen] = useState<boolean>(false);
  const [currentLBData, setCurrentLBData] = useState<any>();
  const [endOfMonth, setEndOfMonth] = useState<null | TimeToEnd>(null);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setHighlightsFilter(value);
  };

  function format(v: number) {
    return Number(v.toFixed(2)).toLocaleString('en-US');
  }

  const snapshots = ['1st Place', '2nd Place', '3rd Place'];
  let viewing: LeaderboardEntry | undefined;
  if (top3) {
    viewing =
      highlightFilter == '1st Place' ? top3[0] : highlightFilter == '2nd Place' ? top3[1] : top3[2];
  }

  useEffect(() => {
    getLeaderboardResetDate().then((r) => {
      // const now = DateTime.now().setZone(DEFAULT_TIME_ZONE);

      // setTime(r - now.toMillis());
      setTime(r - new Date().getTime());
    });

    getTop3().then((response) => {
      setTop3(response);
    });
  }, []);

  const eventTime = msToTime(time);

  useEffect(() => {
    if (currentLBData && currentLBData.length > 0 && tab === 0) {
      setData(currentLBData);
    } else if (tab === 0) {
      getLeaderboard().then((response) => {
        setCurrentLBData(response);
        setData(response);
      });
    } else {
      setData(PrevMonthWagerLB);
    }
  }, [snapshotFilter, tab]);

  useEffect(() => {
    const interval = setInterval(() => {
      setEndOfMonth(timeToNextMonth);
    }, 1000);
    return () => clearInterval(interval);
  }, [timeToNextMonth]);

  return (
    <>
      <div className='App-contents Challenges VIP'>
        <div className='Landing-content'>
          <br />
          <br />
          <div className='Landing-Coins'>
            <div className='Gold'>
              <img src='/coin/goldcoin.png' />
            </div>
            <div className='Silver'>
              <img src='/coin/silvercoin2.png' />
            </div>
          </div>
          <div className='Landing-heading Heading-Leaderboard'>
            <h2 className='Main-heading' style={{ fontSize: '2.9vw', margin: '0', padding: '0' }}>
              <span style={{ fontFamily: 'Helvetica', color: 'white' }}>$</span>
              50,000
            </h2>
            <h2 className='Main-heading' style={{ margin: '0', padding: '0' }}>
              Leaderboard
            </h2>
            <div className='Heading-divider' />
            <div className='Leaderboard-btn '>
              <div
                style={{
                  display: 'flex',
                  gap: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Button
                  onClick={() => setRulesOpen(!open)}
                  borderRadius='6px'
                  className=''
                  customStyle={{ width: 'auto' }}>
                  <img width={15} src='https://btcs.gg/coin/rules.svg' />
                  Rules
                </Button>
                <Link style={{ display: 'block', width: 'auto' }} to={routes.challenges}>
                  <Button borderRadius='6px'>Exclusive Challenges</Button>
                </Link>
              </div>
            </div>
          </div>
          <div
            className='Active-filter'
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: 40,
            }}>
            <div className={tab === 0 ? `Active` : ''} onClick={() => setTab(0)}>
              Current Month
            </div>
            <div className={tab === 1 ? `Active` : ''} onClick={() => setTab(1)}>
              Previous Month
            </div>
          </div>
          <br />
          <br />
          <div className='Leaderboard-Pedestal-container'>
            <div className='Leaderboard-Pedestal'>
              <div className='Rankings'>
                <div className='Ranking'>
                  <div className='Content Silver'>
                    <div className='User-icon'>
                      <img
                        src={
                          data && data[1]
                            ? data[1].image ?? 'https://btcs.gg/newcoin.svg'
                            : 'https://btcs.gg/newcoin.svg'
                        }
                      />
                    </div>
                    <div className='User-info-container User-info-container-silver'>
                      <div className='User-info'>
                        <div className={s.pedestalCard__prizes}>
                          <div className={s.pedestalCard__prize}>
                            <div className={s.pedestalCard__prize_title}>LB Prize</div>
                            <div className={s.pedestalCard__prize_count}>
                              ${data && data[1] ? numberWithCommas(data[1].prize, 0) : '-'}
                            </div>
                          </div>

                          <div className={s.pedestalCard__prize}>
                            <div className={s.pedestalCard__prize_title}>VIP Earned</div>
                            <div className={s.pedestalCard__prize_count}>
                              $
                              {data && data[1]
                                ? calculateVIPRewards(data[1].totalWagered, true)
                                : '-'}
                            </div>
                          </div>
                        </div>
                        <h4>${data ? data[1] && format(data[1].totalWagered) : '-'}</h4>
                        <span>Wagered</span>
                        <h4 className='Druk'>{data ? data[1] && data[1].username : '-'}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='Ranking'>
                  <div className='Content Gold'>
                    <div className='User-icon'>
                      <img
                        src={
                          data && data[0]
                            ? data[0].image ?? 'https://btcs.gg/newcoin.svg'
                            : 'https://btcs.gg/newcoin.svg'
                        }
                      />
                    </div>
                    <div className='User-info-container User-info-container-gold'>
                      <div className='User-info'>
                        <div className={s.pedestalCard__prizes}>
                          <div className={s.pedestalCard__prize}>
                            <div className={s.pedestalCard__prize_title}>LB Prize</div>
                            <div className={s.pedestalCard__prize_count}>
                              ${data && data[0] ? numberWithCommas(data[0].prize, 0) : '-'}
                            </div>
                          </div>

                          <div className={s.pedestalCard__prize}>
                            <div className={s.pedestalCard__prize_title}>VIP Earned</div>
                            <div className={s.pedestalCard__prize_count}>
                              $
                              {data && data[0]
                                ? calculateVIPRewards(data[0].totalWagered, true)
                                : '-'}
                            </div>
                          </div>
                        </div>
                        <h4>${data ? data[0] && format(data[0].totalWagered) : '-'}</h4>
                        <span>Wagered</span>
                        <h4 className='Druk'>{data ? data[0] && data[0].username : '-'}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='Ranking'>
                  <div className='Content Bronze'>
                    <div className='User-icon'>
                      <img
                        src={
                          data && data[2]
                            ? data[2].image ?? 'https://btcs.gg/newcoin.svg'
                            : 'https://btcs.gg/newcoin.svg'
                        }
                      />
                    </div>
                    <div className='User-info-container'>
                      <div className='User-info'>
                        <div className={s.pedestalCard__prizes}>
                          <div className={s.pedestalCard__prize}>
                            <div className={s.pedestalCard__prize_title}>LB Prize</div>
                            <div className={s.pedestalCard__prize_count}>
                              ${data && data[2] ? numberWithCommas(data[2].prize, 0) : '-'}
                            </div>
                          </div>

                          <div className={s.pedestalCard__prize}>
                            <div className={s.pedestalCard__prize_title}>VIP Earned</div>
                            <div className={s.pedestalCard__prize_count}>
                              $
                              {data && data[2]
                                ? calculateVIPRewards(data[2].totalWagered, true)
                                : '-'}
                            </div>
                          </div>
                        </div>
                        <h4>${data ? data[1] && format(data[2].totalWagered) : '-'}</h4>
                        <span>Wagered</span>
                        <h4 className='Druk'>{data ? data[2] && data[2].username : '-'}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='Leaderboard-Pedestal-container Leaderboard-Pedestal-container-mobile'>
            <br />
            <br />
            <br />
            <div className='Leaderboard-Pedestal'>
              <div className='Rankings'>
                <div className='Ranking'>
                  <div
                    className={
                      'Content ' +
                      (highlightFilter == '1st Place'
                        ? 'Gold'
                        : highlightFilter == '2nd Place'
                        ? 'Silver'
                        : 'Bronze')
                    }>
                    <div className='User-icon'>
                      <img
                        src={
                          viewing
                            ? viewing.image ?? 'https://btcs.gg/newcoin.svg'
                            : 'https://btcs.gg/newcoin.svg'
                        }
                      />
                    </div>
                    <div className='User-info-container User-info-container-gold'>
                      <div className='User-info'>
                        <div className='leaderboard-username'>
                          <span className='Username'>${viewing ? viewing.prize : '-'}</span>
                        </div>
                        <span>Prize</span>
                        <h4>${viewing ? format(viewing.totalWagered) : '-'}</h4>
                        <span>Wagered</span>
                        <h4 className='Druk'>{viewing ? viewing.username : '-'}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='Pedestal-View-Number'>
            <div
              onClick={() => setHighlightsFilter('1st Place')}
              className={`First ${highlightFilter == '1st Place' ? 'First-Active' : ''}`}>
              1
            </div>
            <div
              onClick={() => setHighlightsFilter('2nd Place')}
              className={`Second ${highlightFilter == '2nd Place' ? 'Second-Active' : ''}`}>
              2
            </div>
            <div
              onClick={() => setHighlightsFilter('3rd Place')}
              className={`Third ${highlightFilter == '3rd Place' ? 'Third-Active' : ''}`}>
              3
            </div>
          </div>

          {tab === 0 && (
            <div className='Landing-heading Heading-Leaderboard-sub'>
              <p className='Main-subheading Leaderboard-subheading'>
                <span>New</span> leaderboard starts in
              </p>

              <EndCounter time={endOfMonth} />
            </div>
          )}

          <WagerLB rankings={tab === 1 ? PrevMonthWagerLB : data} />

          {/* <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>

                    <div className="Account-settings-container Leaderboard-container Leaderboard-container-Less-padding"
                         style={{width: '800px', maxWidth: '100%', overflowX: 'scroll', overflowY: 'hidden', padding: '0', borderRadius: '5px', border: '3px solid #7e8ab3'}}>

                        <ul className="Heading">
                            <li style={{paddingLeft: '10px'}}><span>Rank</span></li>
                            <li><span>User</span></li>
                            <li><span>Wagered</span></li>
                            <li><span>Prize</span></li>
                        </ul>
                        {
                            data.slice(3).map((entry, index) => {
                                return (<ul>
                                    <li><p>{index + 4}</p></li>
                                    <li style={{display: 'flex', justifyContent: 'left'}}>
                                        <img width={30} style={{borderRadius: '50%', padding: '0 15px'}} src={entry.image ?? 'https://btcs.gg/newcoin.svg'} />
                                        {entry.username}...
                                    </li>
                                    <li>${numberWithCommas(entry.totalWagered)}</li>
                                    <li>${entry.prize ?? '-'}</li>
                                </ul>)
                            })
                        }
                    </div>
                </div> */}

          <RulesModal open={open} onClose={() => setRulesOpen(!open)} />
        </div>
      </div>
    </>
  );
}
