import React from 'react';
import s from './style.module.scss';

import Button from 'src/ui/Button';

// import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import CoinsImage from '../../../assets/images/BTCsShuffleCoins.png';
import EndCounter from 'src/components/EndCounter';
import { createImageUrl, createPublicImageUrl } from 'src/utils/url';
import useModal from 'src/hooks/useModal';
import useAuthStore from 'src/store/authStore';
import { IRaffle, RaffleStatus } from 'src/@types/raffles';

interface IRaffleCardProps {
  item: IRaffle;
  onClick?: () => void;
}

const GiveawayCard = ({ item, onClick }: IRaffleCardProps) => {
  return (
    <>
      <div className={s.card}>
        <div className={s.card__bg} />

        <div className={s.card__content}>
          <div className={s.card__image}>
            <img
              src={item.imageId ? createPublicImageUrl(item?.imageId) : CoinsImage}
              alt='giveaway-image'
            />
          </div>

          <div className={s.card__content}>
            <div className={s.card__title}>{item.name}</div>

            <div className={s.card__text}>
              <div className={s.card__description}>{item.description}</div>
              <ul className={s.card__descList}>
                {item.meta.descriptionItems.map((descItem, index) => (
                  <li key={index}>{descItem}</li>
                ))}
              </ul>
            </div>

            <EndCounter status={item.status} endDate={item.endDate} />

            {item.status === RaffleStatus.UPCOMING ? (
              <Button onClick={onClick} isOutlined>
                Review
              </Button>
            ) : (
              <Button onClick={onClick}>Join here</Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GiveawayCard;
