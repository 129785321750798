import { Dialog } from '@mui/material';
import { useState } from 'react';
import { dialogStyles, ModalProps } from 'src/layouts/AdminLayout';
import { BlacklistRequest, StaticList } from 'src/types';
import { axiosPost } from 'src/utils/httpClient';

export type BlacklistModalProps = {
  strings: string;
  title: string;
  endpoint: string;
} & ModalProps;

function EditBlacklistModal(props: BlacklistModalProps) {
  const { onClose, open, title, endpoint } = props;

  const [text, setText] = useState<string>(props.strings);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const editBlacklist = async (request: BlacklistRequest): Promise<StaticList> =>
    axiosPost(endpoint, request);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
      <h2>{title}</h2>
      <div className='Btcs-form'>
        <div className='Form-input-field'>
          <h6>List</h6>
          <div className='Form-input'>
            <textarea defaultValue={text} onChange={(e) => setText(e.target.value)} />
          </div>
        </div>
        <div className='Form-submission'>
          <button
            className='Alt'
            onClick={() => {
              if (submitting) {
                return;
              }
              if (!text) {
                return;
              }
              setSubmitting(true);
              editBlacklist({
                list: text,
              }).then((r) => {
                window.location.reload();
              });
            }}>
            {submitting ? 'Submitting...' : 'Save'}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default EditBlacklistModal;
