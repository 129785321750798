import { Avatar, Chip, Dialog, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { dialogStyles, ModalProps, useStyles } from 'src/layouts/AdminLayout';
import {
  CreateSlotBattleRequest,
  EditSlotBattleRequest,
  ShuffleSlotsGame,
  SlotBattle,
} from 'src/types';
import { axiosGet, axiosPost, axiosPut } from 'src/utils/httpClient';

export type SlotBattleModalProps = {
  editing?: SlotBattle;
  setItems: any;
} & ModalProps;

function AddSlotBattleModal(props: SlotBattleModalProps) {
  const classes = useStyles();
  const { onClose, open, setItems } = props;

  const [ends, setEnds] = useState<string | undefined>(undefined);
  const [search, setSearch] = useState<string>('');
  const [results, setResults] = useState<Array<ShuffleSlotsGame>>([]);
  const [selected, setSelected] = useState<Array<ShuffleSlotsGame>>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const games = async (page: number): Promise<Array<ShuffleSlotsGame>> =>
    axiosGet(`/moderate/slot-games?page=${page}`);
  const searchGames = async (name: string, page: number): Promise<Array<ShuffleSlotsGame>> =>
    axiosGet(`/moderate/slot-games-search?name=${name}&page=${page}`);
  const createBattle = async (request: CreateSlotBattleRequest): Promise<Array<SlotBattle>> =>
    axiosPost(`/moderate/create-battle`, request);
  const editBattle = async (request: EditSlotBattleRequest): Promise<Array<SlotBattle>> =>
    axiosPut(`/moderate/edit-battle`, request);

  const handleClose = () => {
    onClose();
  };

  const removeFromSelected = (n: ShuffleSlotsGame) => {
    const alreadyAdded = selected.find((s) => s.name == n.name);
    if (!alreadyAdded) {
      return;
    }
    setSelected(selected.filter((c) => c.name != n.name));
  };
  const addToSelected = (n: ShuffleSlotsGame) => {
    const alreadyAdded = selected.find((s) => s.name == n.name);
    if (alreadyAdded) {
      return;
    }
    const newSelected: any[] = [];
    newSelected.push(n);
    selected.map((c) => newSelected.push(c));
    setSelected(newSelected);
  };

  useEffect(() => {
    games(1).then((r) => setResults(r));
  }, []);

  useEffect(() => {
    if (search) {
      searchGames(search, 0).then((r) => setResults(r));
    }
  }, [search]);

  useEffect(() => {
    if (props.editing) {
      setEnds(props.editing.votingEnd);
      const selected: Array<ShuffleSlotsGame> = [];
      const keys = Object.keys(props.editing.slots);
      if (keys.length > 0) {
        // @ts-ignore
        for (let i = 0; i < keys.length; i++) {
          const gameData = keys[i].split('::http');
          selected.push({
            name: gameData[0],
            image: 'http' + gameData[1],
          });
        }
      }
      setSelected(selected);
    }
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
      <h2>{props.editing ? 'Edit' : 'Add'} Slot Battle</h2>
      <div className='Btcs-form'>
        <div className='Form-input-field'>
          <h6>Voting Ends In</h6>
          <div className='Form-input'>
            <input
              type='datetime-local'
              defaultValue={ends}
              onChange={(e) => {
                setEnds(e.target.value);
              }}
            />
          </div>
        </div>
        <div className='Form-input-field'>
          <h6>Games</h6>
          <Grid xs={5} spacing={1}>
            {selected.map((s) => (
              <Chip
                onClick={() => removeFromSelected(s)}
                className={classes.chip}
                avatar={<Avatar alt={s.name} src={s.image} />}
                label={s.name}
                variant='outlined'
              />
            ))}
          </Grid>

          <div className='Bonus-hunt-search'>
            <div className='search-input'>
              <img src='/navigation/search.svg' width={20} />
              <input
                type='text'
                placeholder='Search'
                defaultValue={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='Form-input-field'>
          <div className='Games-search-result'>
            {results.map((r: ShuffleSlotsGame, i: number) => {
              return (
                <div className='Result-entry'>
                  <ul
                    onClick={() => {
                      addToSelected(r);
                    }}>
                    <li>
                      <span>{i + 1}</span>
                    </li>
                    <li>
                      <img src={r.image} width={25} />
                    </li>
                    <li>
                      <span>{r.name}</span>
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        </div>

        <div className='Form-submission'>
          <button
            className='Alt'
            onClick={() => {
              if (submitting) {
                return;
              }
              if (!ends || selected.length < 2 || selected.length > 8) {
                return;
              }
              setSubmitting(true);
              if (props.editing) {
                editBattle({
                  id: props.editing.id,
                  expiry: ends,
                  games: selected.map((s) => s.name),
                }).then((r) => {
                  setEnds(undefined);
                  setSearch('');
                  setResults([]);
                  setSelected([]);
                  setSubmitting(false);
                  handleClose();
                  setItems(r);
                });
              } else {
                createBattle({
                  expiry: ends,
                  games: selected.map((s) => s.name),
                }).then((r) => {
                  setEnds(undefined);
                  setSearch('');
                  setResults([]);
                  setSelected([]);
                  setSubmitting(false);
                  handleClose();
                  setItems(r);
                });
              }
            }}>
            {submitting ? 'Submitting...' : (props.editing ? 'Save' : 'Add') + ' Slot Battle'}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default AddSlotBattleModal;
