import React, { useEffect, useState } from 'react';
import s from './style.module.scss';
import { DateTime } from 'luxon';
import { DEFAULT_TIME_ZONE } from 'src/constants/time';
import { RaffleStatus } from 'src/@types/raffles';

interface IEndCounterProps {
  title?: string;
  status?: RaffleStatus;
  endDate: string;
}

const EndCounter = ({ title, status, endDate }: IEndCounterProps) => {
  const calculateTimeLeft = () => {
    const now = DateTime.now().setZone(DEFAULT_TIME_ZONE);
    const targetDate = DateTime.fromISO(endDate, { zone: DEFAULT_TIME_ZONE });

    const difference = targetDate.diff(now, ['days', 'hours', 'minutes', 'seconds']).toObject();

    if (
      difference.days! > 0 ||
      difference.hours! > 0 ||
      difference.minutes! > 0 ||
      difference.seconds! > 0
    ) {
      return {
        days: Math.floor(difference.days!),
        hours: Math.floor(difference.hours!),
        minutes: Math.floor(difference.minutes!),
        seconds: Math.floor(difference.seconds!),
      };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);

  // if (timeLeft.difference <= 0) {
  //   const formatDate = (dateStr: string) => {
  //     const dateObj = new Date(dateStr);
  //     const day = dateObj.getDate().toString().padStart(2, '0');
  //     const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  //     const year = dateObj.getFullYear();

  //     return `${day}.${month}.${year}`;
  //   };

  //   return (
  //     <div className={s.block}>
  //       {title && <div className={s.block__title}>{title}</div>}

  //       <div className={s.block__endDate}>
  //         {formatDate(endDate)}
  //         <span> Completed</span>
  //       </div>
  //     </div>
  //   );
  // }

  if (
    timeLeft.days === 0 &&
    timeLeft.hours === 0 &&
    timeLeft.minutes === 0 &&
    timeLeft.seconds === 0
  ) {
    const formatDate = (dateStr: string) => {
      const estDate = DateTime.fromISO(dateStr, { zone: DEFAULT_TIME_ZONE });
      return estDate.toFormat('dd.MM.yyyy');
    };

    return (
      <>
        {status === RaffleStatus.ACTIVE ||
        status === RaffleStatus.UPCOMING ||
        status === RaffleStatus.PENDING ? null : (
          <div className={s.block}>
            {title && <div className={s.block__title}>{title}</div>}

            <div className={s.block__endDate}>
              {formatDate(endDate)}
              <span> Completed</span>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div className={s.block}>
      {title && <div className={s.block__title}>{title}</div>}

      <div className={s.block__counter}>
        <div className={s.block__count}>
          <span>{String(timeLeft.days).padStart(2, '0')}</span>
          <span>DAY</span>
        </div>
        :
        <div className={s.block__count}>
          <span>{String(timeLeft.hours).padStart(2, '0')}</span>
          <span>HR</span>
        </div>
        :
        <div className={s.block__count}>
          <span>{String(timeLeft.minutes).padStart(2, '0')}</span>
          <span>MIN</span>
        </div>
        :
        <div className={s.block__count}>
          <span>{String(timeLeft.seconds).padStart(2, '0')}</span>
          <span>SEC</span>
        </div>
      </div>
    </div>
  );
};

export default EndCounter;
