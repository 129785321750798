import React from 'react';

import { useState } from 'react';

import { Header } from 'src/components/Header';

import { ToastContainer, Slide } from 'react-toastify';

import 'src/assets/CSS/null.scss';
import 'src/assets/CSS/App.scss';
import 'src/assets/CSS/App.responsive.scss';
import 'src/assets/CSS/global.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AuthProvider from 'src/providers/AuthProvider';
import MenuBurger from 'src/components/MenuBurger';
import Footer from 'src/components/Footer';
import { Outlet, ScrollRestoration } from 'react-router';

// TODO: Remove this when the issue is fixed
window.ResizeObserver = class {
  observe() {}
  unobserve() {}
  disconnect() {}
};

const queryClient = new QueryClient();

const AppLayout = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        {/* <AppRouter> */}

        <div className='App'>
          <AuthProvider>
            <div className='App-header-container'>
              <Header onToggleMenu={(value: boolean) => setMenuOpen(value)} />
            </div>

            <ScrollRestoration
              getKey={(location, matches) => {
                return location.pathname;
              }}
            />
            <Outlet />

            <MenuBurger isOpen={isMenuOpen} onClose={() => setMenuOpen(false)} />
            <Footer />
          </AuthProvider>
        </div>

        <ToastContainer
          position='bottom-right'
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
          transition={Slide}
        />
        {/* </AppRouter> */}
      </QueryClientProvider>
    </>
  );
};

export default AppLayout;
