import React, { useEffect, useState } from 'react';
import {
  Battle,
  BattleWinningWager,
  SlotBattleBetRequest,
} from '../types';
import '../assets/CSS/SlotBattles.scss';
import '../assets/CSS/SlotBattles.responsive.scss';
import CloseIcon from '@mui/icons-material/Close';
import { axiosGet, axiosPut } from '../utils/httpClient';
import { formatDateYearNoTime, formatDateYearTime } from 'src/components/Crates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import Dialog from '@mui/material/Dialog';
import { dialogStyles, ModalProps } from 'src/layouts/AdminLayout';
import useAuthStore from 'src/store/authStore';

const getWinsData = async (): Promise<Array<BattleWinningWager>> =>
  axiosGet(`/general/top-100-battles`);
const getAllBattles = async (): Promise<Array<Battle>> => axiosGet(`/general/slot-battles`);
const wagerRequest = async (request: SlotBattleBetRequest): Promise<Array<Battle>> =>
  axiosPut(`/player/battle-wager`, request);

type BattleData = {
  game: string;
  image: string;
  votes: any;
};

type Wager = {
  battle: string;
  game: string;
  gamePerCent: number;
  gameMultiplier: number;
  image: string;
  amount: number;
};

type PayoutModalProps = {
  data: Array<BattleWinningWager>;
} & ModalProps;

function TopPayoutsModal(props: PayoutModalProps) {
  const { onClose, open, data } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
      <h2>Best Slot Battle Wins</h2>
    </Dialog>
  );
}

const BattleList = (
  username: string | undefined,
  data: Array<Battle>,
  tab: number,
  currentWager: Wager | undefined,
  wins: Array<BattleWinningWager>,
  wager: (username: string | undefined, battleId: string, game: string, image: string) => void,
) => {
  if (tab == 3) {
    return (
      <div className='Slot-battle-top-payouts-container'>
        <div className='Slot-battle-top-payouts'>
          <ul className='Headers'>
            <li>USER</li>
            <li>MULTI</li>
            <li>SLOTS</li>
          </ul>
          {wins.map((entry) => {
            const icons: Array<string> = [];
            const names: Array<string> = [];
            Object.keys(entry.slots).forEach((b) => {
              names.push(b);
            });
            Object.values(entry.slots).forEach((b) => {
              icons.push(b as string);
            });
            return (
              <ul>
                <li>
                  <div className='Payout-user'>
                    <img src={entry.image} />
                    <h3>{entry.username}</h3>
                  </div>
                </li>
                <li>{entry.multiplier.toFixed(2)}x</li>
                <li className='Slot-Icon'>
                  {icons.map((path, index) => (
                    <img src={path} title={names[index]} />
                  ))}
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    );
  }
  const visible = data.filter((battle) => {
    const votingEnabled = new Date().getTime() < new Date(battle.votingEnd).getTime();
    switch (tab) {
      case 0:
        return votingEnabled;
      case 2:
        const exists = battle.votes.find((vote) => vote.username == username);
        return exists;
      default:
        return true;
    }
  });
  if (visible.length == 0) {
    return (
      <div className='Slot-battles'>
        <span className='Disclaimer'>No Slot Battles found.</span>
      </div>
    );
  }
  return (
    <div className='Slot-battles'>
      {visible.map((battle) => {
        const votingEnabled = new Date().getTime() < new Date(battle.votingEnd).getTime();
        const battleData: Array<BattleData> = [];
        Object.keys(battle.slots).map((key: string, index: number) => {
          const vals = key.split('::');
          battleData.push({
            game: vals[0].toString(),
            image: vals[1].toString(),
            votes: Object.values(battle.slots)[index],
          });
        });
        return (
          <div className={`Battle ${votingEnabled ? 'Battle-slots-votable' : ''}`}>
            <div className='Battle-header'>
              <div className='Streamer'>
                <img src={battle.streamerImage} />
                <h3>{battle.streamer}</h3>
              </div>
              <div className='Battle-Date'>
                <h6 className={votingEnabled ? 'Live' : ''}>
                  {votingEnabled ? (
                    <FontAwesomeIcon className={`StatusSymbol`} icon={faCircle} />
                  ) : (
                    <></>
                  )}
                  {votingEnabled ? 'Live' : formatDateYearNoTime(battle.votingEnd)}
                </h6>
              </div>
            </div>
            <div className='Battle-slots'>
              {battleData.map((slot) => {
                let isSelected = false;
                const isWageredOn = battle.votes.find(
                  (vote) => vote.username == username && vote.game == slot.game,
                );
                const isWinningSlot = battle.winner == slot.game;
                const votes = battle.votes.filter((vote) => vote.game == slot.game).length;
                let totalPerCent = (votes / battle.votes.length) * 100;
                if (currentWager) {
                  if (currentWager.game == slot.game) {
                    isSelected = true;
                  }
                }
                if (!totalPerCent) {
                  totalPerCent = 0;
                }
                return (
                  <div
                    className={`Slot ${
                      isWinningSlot
                        ? 'Winning'
                        : isWageredOn
                        ? 'Wagered'
                        : isSelected
                        ? 'Selected'
                        : ''
                    }`}
                    onClick={() => {
                      if (votingEnabled) {
                        wager(username, battle.id, slot.game, slot.image);
                      }
                    }}>
                    <div className='Slot-title'>
                      <img src={slot.image} />
                      <h3>{slot.game}</h3>
                    </div>
                    <div className='Slot-votes'>
                      <span>{totalPerCent.toFixed(2)}%</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const SlipToggleIcon = () => {
  return (
    <svg height='45' viewBox='0 0 24 24' width='45' xmlns='http://www.w3.org/2000/svg'>
      <path d='m21.5 22h-19c-1.378 0-2.5-1.122-2.5-2.5v-2c0-.276.224-.5.5-.5h.5c1.103 0 2-.897 2-2s-.897-2-2-2h-.5c-.276 0-.5-.224-.5-.5v-2c0-1.378 1.122-2.5 2.5-2.5h19c1.378 0 2.5 1.122 2.5 2.5v2c0 .276-.224.5-.5.5h-.5c-1.103 0-2 .897-2 2 0 .534.208 1.036.586 1.414.377.378.879.586 1.413.586h.5c.276 0 .5.224.5.5v2c.001 1.378-1.121 2.5-2.499 2.5zm-20.5-4v1.5c0 .827.673 1.5 1.5 1.5h19c.827 0 1.5-.673 1.5-1.5v-1.5h-.001c-.801 0-1.554-.312-2.12-.878-.567-.567-.879-1.321-.879-2.122 0-1.654 1.346-3 3-3v-1.5c0-.827-.673-1.5-1.5-1.5h-19c-.827 0-1.5.673-1.5 1.5v1.5c1.654 0 3 1.346 3 3s-1.346 3-3 3z' />
      <path d='m8.5 10c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5s.5.224.5.5v1c0 .276-.224.5-.5.5z' />
      <path d='m8.5 18.461c-.276 0-.5-.224-.5-.5v-1.691c0-.276.224-.5.5-.5s.5.223.5.5v1.692c0 .276-.224.499-.5.499zm0-4.23c-.276 0-.5-.224-.5-.5v-1.692c0-.276.224-.5.5-.5s.5.224.5.5v1.692c0 .276-.224.5-.5.5z' />
      <path d='m8.5 22c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5s.5.224.5.5v1c0 .276-.224.5-.5.5z' />
      <path d='m2.5 9c-.203 0-.394-.124-.468-.325-.097-.259.034-.547.293-.643l15.9-5.937c.773-.29 1.681.144 1.945.923l1.803 5.321c.088.262-.052.545-.313.634-.259.089-.545-.052-.634-.313l-1.803-5.321c-.088-.26-.39-.402-.649-.308l-15.9 5.937c-.057.022-.116.032-.174.032z' />
    </svg>
  );
};

const SlipCloseIcon = () => {
  return <CloseIcon />;
};

export const SlotBattles: React.FC = () => {
  const { user } = useAuthStore();

  const placeWager = (
    username: string | undefined,
    battleId: string,
    game: string,
    image: string,
  ) => {
    const exists = data
      .find((battle) => battle.id == battleId)
      ?.votes.find((vote) => vote.username == username);
    if (!exists && data.find((battle) => battle.id == battleId)) {
      const votes = data
        .find((battle) => battle.id == battleId)!
        .votes.filter((vote) => vote.game == game).length;
      let totalPerCent = (votes / data.find((battle) => battle.id == battleId)!.votes.length) * 100;
      if (!totalPerCent) {
        totalPerCent = 0;
      }
      const overall = 100 - totalPerCent;
      let multiplier = 1 + overall / totalPerCent;
      if (overall == 0) {
        multiplier = 2;
      }
      setWager({
        battle: battleId,
        game: game,
        image: image,
        amount: 0,
        gamePerCent: totalPerCent,
        gameMultiplier: multiplier,
      });
      const newKey = battleId + game;
      setKey(newKey);
    }
    setPanelVisible(true);
  };

  function floorM(v: number) {
    return Number(v.toFixed(0)).toLocaleString('en-US');
  }

  const [panelVisible, setPanelVisible] = React.useState<boolean>(false);
  const [tab, setTab] = useState<number>(1);
  const [key, setKey] = useState<string>('slips');
  const [data, setData] = useState<Array<Battle>>([]);
  const [wins, setWins] = useState<Array<BattleWinningWager>>([]);
  const [wager, setWager] = useState<Wager | undefined>(undefined);
  const [wageringAmount, setWageringAmount] = useState<number>(1);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    getAllBattles().then((r) => {
      setData(r);
    });
  }, []);

  const contentsSelector = panelVisible ? 'Battle-Wagers-visible-content-visible' : '';
  const wagersSelector = panelVisible ? 'Battle-Wagers-visible' : '';
  const toggleSelector = panelVisible ? 'Wagers-toggle-hidden' : '';

  const wagers: Array<Wager> = [];
  const time = new Date().getTime();
  data
    .filter((battle) => new Date(battle.votingEnd).getTime() > time)
    .map((battle) => {
      battle.votes.forEach((vote) => {
        if (vote.username == user?.username) {
          const votes = battle.votes.filter((v2) => v2.game == vote.game).length;
          let totalPerCent = (votes / battle.votes.length) * 100;
          if (!totalPerCent) {
            totalPerCent = 0;
          }
          const overall = 100 - totalPerCent;
          let multiplier = 1 + overall / totalPerCent;
          if (overall == 0) {
            multiplier = 2;
          }
          wagers.push({
            battle: battle.id,
            game: vote.game,
            image: vote.gameImage,
            amount: vote.amount,
            gamePerCent: totalPerCent,
            gameMultiplier: multiplier,
          });
        }
      });
    });
  let wagerPerCentage = 0.0;
  let ratio = 0.0;
  if (wager) {
    data
      .filter((battle) => battle.id == wager.battle)
      .map((battle) => {
        const votes = battle.votes.filter((v2) => v2.game == wager.game).length;
        wagerPerCentage = (votes / battle.votes.length) * 100;
        if (!wagerPerCentage) {
          wagerPerCentage = 0;
        }
        ratio = 1 + (100 - wagerPerCentage) / 100;
      });
  }
  return (
    <>
      <div className='App-contents Battles-contents Challenges VIP'>
        <div className='Landing-content Slot-Battles-Content-100'>
          <br />
          <br />
          <br />
          <div className='Landing-heading'>
            <h2 className='Main-heading Main-heading-larger'>Slot Battles</h2>
            <div className='Heading-divider' />
            <p className='Main-subheading' style={{ display: 'flex', justifyContent: 'center' }}>
              You have{' '}
              <div className='Balance BalanceNew'>
                <img src='/newcoin.svg' />
                <div className='Points-value'>{user ? user.points.toLocaleString('en-US') : 0}</div>
              </div>
              Points
            </p>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <div className='Slot-Battles-container'>
            <div className='Slot-Battles-content-container'>
              <div className='table-header'>
                <div className='Active-filter-slot-battles'>
                  <div
                    className={tab == 0 ? 'Custom-button2 Active' : 'Custom-button2'}
                    onClick={() => setTab(0)}>
                    Live
                  </div>
                  <div
                    className={tab == 1 ? 'Custom-button2 Active' : 'Custom-button2'}
                    onClick={() => setTab(1)}>
                    Previous
                  </div>
                  <div
                    className={tab == 2 ? 'Custom-button2 Active' : 'Custom-button2'}
                    onClick={() => setTab(2)}>
                    My Bets
                  </div>
                  <div
                    className={tab == 3 ? 'Custom-button2 Active' : 'Custom-button2'}
                    onClick={() => {
                      setTab(3);
                      getWinsData().then((r) => {
                        setWins(r);
                      });
                    }}>
                    Biggest Wins
                  </div>
                </div>
              </div>

              {BattleList(user?.username, data, tab, wager, wins, placeWager)}

              {tab == 3 ? (
                <></>
              ) : (
                <div className='Custom-Paginated'>
                  <div className='Pagination-container'>
                    <div>
                      Showing <span>1</span> of <span>1</span>
                    </div>
                    <div className='Custom-button'>Previous</div>
                    <div className='Custom-button'>Next</div>
                  </div>
                </div>
              )}

              <div
                className={`SlipToggle ${toggleSelector}`}
                onClick={() => {
                  setPanelVisible(!panelVisible);
                }}>
                {SlipToggleIcon()}
              </div>
            </div>
          </div>
        </div>
        <div className={`Battle-Wagers ${wagersSelector}`}>
          <div className={`Battle-Wagers-visible-content ${contentsSelector}`}>
            <div className='Wagers-content' key={key}>
              <div className='Section-heading Margined-Heading-1'>
                <h4>Bets</h4>
                <div
                  onClick={() => {
                    setPanelVisible(!panelVisible);
                  }}>
                  {SlipCloseIcon()}
                </div>
              </div>

              <div className='Wagers-bet-options'>
                <div className='Wagers-slots'>
                  {wagers.map((wager) => {
                    return (
                      <div className='Wager-slot'>
                        <div
                          className='Wager-image'
                          style={{ backgroundImage: "url('" + wager.image + "')" }}></div>
                        <div className='Wager-content'>
                          <div className='Wager-game'>
                            <h6>{wager.game}</h6>
                          </div>
                          <div className='Wager-metrics'>
                            <h6>{wager.gamePerCent.toFixed(2)}%</h6>
                            <h4>
                              <p style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className='Balance'>
                                  <img src='/currency.svg' />
                                  <div className='Points-value'>
                                    {floorM(wager.amount * wager.gameMultiplier)}
                                  </div>
                                </div>
                              </p>
                            </h4>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {wager ? (
                    <div className='Wager-slot'>
                      <div
                        className='Wager-image'
                        style={{ backgroundImage: "url('" + wager.image + "')" }}></div>
                      <div className='Wager-content'>
                        <div className='Wager-game'>
                          <h6>{wager.game}</h6>
                        </div>
                        <div className='Wager-metrics'>
                          <h6>{wagerPerCentage.toFixed(2)}%</h6>
                          <h4>{formatDateYearTime(new Date())}</h4>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className='Wagers-bet-options-content'>
                  <div className='Wagers-slot-count'>
                    <span>Summary</span>
                  </div>
                  <div className='Wagers-payout-rates'>
                    <ul>
                      <li>Payout Rate</li>
                      <li>Payout</li>
                    </ul>
                    <ul>
                      <li>1 : {ratio.toFixed(2)}</li>
                      <li>
                        <p style={{ display: 'flex', justifyContent: 'center' }}>
                          <div className='Balance'>
                            <img src='/newcoin.svg' />
                            <div className='Points-value'>{floorM(ratio * wageringAmount)}</div>
                          </div>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className='Wagers-input'>
                    <input
                      type='number'
                      defaultValue={wageringAmount}
                      min={1}
                      onChange={(e) => setWageringAmount(Number(e.target.value))}
                    />
                  </div>
                </div>
              </div>

              <div className='Wagers-buttons'>
                <button
                  className={wager ? '' : 'Alt'}
                  onClick={() => {
                    if (!wager) {
                      return;
                    }
                    wagerRequest({
                      uuid: wager.battle,
                      game: wager.game,
                      points: wageringAmount,
                    }).then((r) => {
                      setData(r);
                      setWageringAmount(1);
                      setWager(undefined);
                    });
                  }}>
                  Place Bet
                </button>
                <button
                  onClick={() => {
                    setWager(undefined);
                    setWageringAmount(1);
                  }}>
                  Clear
                </button>
              </div>
            </div>
            <TopPayoutsModal
              key={'top-slotbattle-payouts'}
              open={open}
              onClose={() => setOpen(false)}
              data={wins}
            />
          </div>
        </div>
      </div>
    </>
  );
};
