import { IRaffle } from 'src/@types/raffles';
import { axiosGet } from 'src/utils/httpClient';

export interface IGetRafflesResponse {
  data: IRaffle[];
  total: number;
}

const userService = {
  async getUserSettings() {
    const response = await axiosGet(`/player/settings`);

    return response;
  },

  getUserDetails: async () => {
    const response = await axiosGet(`/player/details`);

    return response;
  },
};

export default userService;
